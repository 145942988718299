import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { fromPromise } from 'rxjs/observable/fromPromise';

import { ModalAlertComponent } from '../modals/modal-alert/modal-alert.component';
import { ModalConfirmationComponent } from '../modals/modal-confirmation/modal-confirmation.component';
import { ModalPickerComponent } from '../modals/modal-picker/modal-picker.component';
import { IPickerOption } from '../modals/modal-picker/picker-option.interface';
import { ModalPromptComponent } from '../modals/modal-prompt/modal-prompt.component';

@Injectable({
	providedIn: 'root'
})
export class ModalService {
	protected modalOptions: NgbModalOptions = {
		// Prevent the backdrop or keyboard from closing the modal
		backdrop: 'static',
		keyboard: false
	};

	constructor(protected modalService: NgbModal) {}

	public alert(title: string, description: string): Observable<null> {
		const modal = this.modalService.open(ModalAlertComponent, this.modalOptions);

		modal.componentInstance.title = title;
		modal.componentInstance.description = description;

		return fromPromise(modal.result);
	}

	public picker(pickerOptions: IPickerOption[], title?: string, description?: string): Observable<IPickerOption[]> {
		const modal = this.modalService.open(ModalPickerComponent, this.modalOptions);

		modal.componentInstance.options = pickerOptions;
		modal.componentInstance.title = title;
		modal.componentInstance.description = description;

		return fromPromise(modal.result);
	}

	public prompt(
		title: string,
		description: string,
		placeholder?: string,
		prefillVaule?: string,
		validator?: (str: string) => boolean
	): Observable<string> {
		const modal = this.modalService.open(ModalPromptComponent, this.modalOptions);

		modal.componentInstance.title = title;
		modal.componentInstance.description = description;
		modal.componentInstance.placeholder = placeholder;
		modal.componentInstance.validator = validator;
		if (prefillVaule) {
			modal.componentInstance.value = prefillVaule;
		}

		return fromPromise(modal.result);
	}

	/**
	 * Zeigt ein modal und zwei buttons an. Der Benutzer kann akzeptieren oder ablehnen.
	 * Das Ergebnis wird als boolean emittiert.
	 * 
	 * @param title 
	 * @param description 
	 */
	public confirmation(title: string, description: string): Observable<boolean> {
		const modal = this.modalService.open(ModalConfirmationComponent, this.modalOptions);

		modal.componentInstance.title = title;
		modal.componentInstance.description = description;

		return fromPromise(modal.result);
	}
}
