<div page page-user-create>
	<div class="row">
		<div class="col-6 offset-3">
			<div class="card">
				<div class="card-header">
					<fa-icon [icon]="icons.plusCircle"></fa-icon> Benutzer anlegen
				</div>
				<div class="card-body">
					<form #createUserForm="ngForm" novalidate (ngSubmit)="submit();">
						<div class="form-group">
							<label>Vorname</label>
							<input type="text" class="form-control" [(ngModel)]="firstname" name="firstname" #firstnameField="ngModel" [ngClass]="{'is-invalid': firstnameField.invalid &&  firstnameField.touched}" placeholder="Max" minlength="2" required>
						</div>
						<div class="form-group">
							<label>Nachname</label>
							<input type="text" class="form-control" [(ngModel)]="lastname" name="lastname" #lastnameField="ngModel" [ngClass]="{'is-invalid': lastnameField.invalid &&  lastnameField.touched}" placeholder="Mustermann" minlength="2" required>
						</div>
						<div class="form-group">
							<label>Username (Login)</label>
							<input type="text" class="form-control" [(ngModel)]="username" name="username" #usernameField="ngModel" [ngClass]="{'is-invalid': usernameField.invalid &&  usernameField.touched}" placeholder="m.mustermann" minlength="3" required>
						</div>
						<div class="form-group">
							<label>E-Mail</label>
							<input type="email" email class="form-control" [(ngModel)]="email" name="email" #emailField="ngModel" [ngClass]="{'is-invalid': emailField.invalid &&  emailField.touched}" placeholder="Max.Mustermann@burningparrot.com" required>
						</div>
						<label>Chatgruppen</label><br>
						<div *ngIf="chatGroups.length > 0"><span class="badge badge-pill badge-primary" *ngFor="let group of getEnabledOptions()">{{group.label}}</span></div>
						<div class="btn btn-link" (tap)="manageChatGroups();">Anpassen</div>
						<br>
						<br>
						<button type="submit" class="btn btn-primary" [disabled]="createUserForm.invalid">Erstellen</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
