import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faEye, faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { from, Observable } from 'rxjs';
import { filter, flatMap, map, reduce, take } from 'rxjs/operators';
import { IProfile } from 'src/app/models/Profile.model';

import { IUser } from '../../models/User.model';
import { ChatService } from '../../service/chat.service';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';

interface IUserWithProfile extends IUser {
	profile: IProfile;
}

@Component({
	selector: 'app-page-user-list',
	templateUrl: './page-user-list.component.html',
	styleUrls: ['./page-user-list.component.scss']
})
export class PageUserListComponent implements OnInit {
	icons = {
		users: faUsers,
		search: faSearch,
		edit: faEdit,
		trash: faTrashAlt,
		view: faEye,
		refresh: faSync
	};


	users$: Observable<IUserWithProfile[]> = this.userService.users$
		.pipe(
			filter((users: IUser[]): boolean => {
				return users !== null;
			}),
			flatMap((users: IUser[]): Observable<IUserWithProfile[]> => {
				// Emit each user
				return from(users)
					.pipe(
						// Get profile for each user
						flatMap((user: IUserWithProfile) => {
							return this.profileService
								.getProfile(user._id)
								.pipe(
									take(1),
									map((profile: IProfile) => {
										user.profile = profile;

										return user;
									})
								);
						}),
						// Merge single users back to an user array
						reduce((acc: IUserWithProfile[], user: IUserWithProfile) => {
							acc.push(user);

							return acc;
						}, []),
					);
			}),
		);
	searchTerm: string = '';

	constructor(
		protected router: Router,
		protected userService: UserService,
		protected profileService: ProfileService,
		protected chatService: ChatService
	) { }

	ngOnInit() { }

	formatDate(dateString: string) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	refresh() {
		this.userService.refreshUsers();
		this.profileService.refreshProfiles();
		this.chatService.refreshConversations();
	}

	filter(searchTerm: string, users: IUserWithProfile[]): IUserWithProfile[] {
		if (!searchTerm || searchTerm.length === 0) {
			return users;
		}

		return users.filter((user: IUserWithProfile): boolean => {
			const terms: string[] = searchTerm.toLowerCase().split(/[ ,]+/);
			const usernameVal: string = user.Username.toLocaleLowerCase();
			const profileLastnameVal: string = user.profile ? user.profile.Lastname.toLocaleLowerCase() : '';
			const profileFirstnameVal: string = user.profile ? user.profile.Firstname.toLocaleLowerCase() : '';

			let result: boolean = false;

			terms.forEach((term: string) => {
				if (usernameVal.indexOf(term) !== -1) {
					result = true;
				}

				if (profileLastnameVal.indexOf(term) !== -1) {
					result = true;
				}

				if (profileFirstnameVal.indexOf(term) !== -1) {
					result = true;
				}
			});

			return result;
		});
	}

	getName(user: IUserWithProfile): string {
		return user.profile ? `${user.profile.Lastname}, ${user.profile.Firstname}` : 'UNBEKANNT';
	}

	navigateTo(target: string): void {
		this.router.navigate(['main/' + target]);
	}
}
