import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IDiskSpace } from '../models/File.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class SystemService {

  diskSpaceState$: BehaviorSubject<IDiskSpace> = new BehaviorSubject<IDiskSpace | undefined>(undefined);

  constructor(
    protected socket: SocketService,
  ) {
    this.observeDiskSpaceState();
  }

  private observeDiskSpaceState() {
    this.socket
      .observe('admin:server:diskSpace:get:success')
      .subscribe((event) => {
        this.diskSpaceState$.next(event.payload);
      });
  }

  resetEventService(): Observable<any> {
    return this.socket
      .pullRequest(
        // sendEventName
        'admin:server:resetEventSystem',
        // sendPayload
        {},
        // successEventName
        'admin:server:resetEventSystem:success',
        // failEventName
        'admin:server:resetEventSystem:fail'
      ).pipe(
        map((event: ISocketEvent): any => {
          return event.payload;
        })
      );
  }

  diskSpaceLow$(limitPercent: number = 90): Observable<boolean> {
    return this.diskSpaceState$.pipe(
      map((state) => {
        if (!state) {
          return false;
        }

        return state.used.percent > limitPercent
      })
    );
  }
}
