import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faHandshake, faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { IUserAgreement } from 'src/app/models/UserAgreement.model';
import { AppStateService } from 'src/app/service/app-state.service';
import { UserAgreementService } from 'src/app/service/user-agreement.service';

@Component({
	selector: 'app-page-user-agreement-list',
	templateUrl: './page-user-agreement-list.component.html',
	styleUrls: ['./page-user-agreement-list.component.scss']
})
export class PageUserAgreementListComponent implements OnInit {

	icons = {
		agreement: faHandshake,
		refresh: faSync,
		search: faSearch,
		trash: faTrashAlt
	};

	searchTerm: string;

	agreements$: Observable<IUserAgreement[]> = this.agreement.agreements$;

	constructor(
		protected agreement: UserAgreementService,
		protected appState: AppStateService,
		protected router: Router,
		protected spinner: NgxSpinnerService
	) { }

	ngOnInit() {
	}

	refresh(): void {
		this.appState.refreshState();
	}

	filter(searchTerm: string, agreements: IUserAgreement[]): IUserAgreement[] {
		if (!searchTerm || searchTerm.length === 0) {
			return agreements;
		}

		return agreements.filter((agreement: IUserAgreement): boolean => {
			const terms: string[] = searchTerm.toLowerCase().split(/[ ,]+/);
			const val: string = agreement.Title.toLocaleLowerCase();

			let result: boolean = false;

			terms.forEach((term: string) => {
				if (val.indexOf(term) !== -1) {
					result = true;
				}
			});

			return result;
		});
	}

	formatDate(dateString: string) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	navigateTo(target: string) {
		this.router.navigate(['main/' + target]);
	}

	navigateToDetail(agreement: IUserAgreement): void {
		if (!agreement) {
			return;
		}
		this.navigateTo('user-agreement-detail/' + agreement._id);
	}

	delete(agreement: IUserAgreement): void {
		this.spinner.show();

		if (!agreement || !agreement._id) {
			return;
		}

		this.agreement
			.delete(agreement._id)
			.subscribe(() => {
				// Success
				this.spinner.hide();
				alert('Erfolg! Die Vereinbarung wurde gelöscht.');
			}, (err) => {
				// Error
				this.spinner.hide();
				alert(`Fehler: Die Vereinbarung "${agreement.Title}" konnte nicht gelöscht werden. Code: ${err.status} - ${err.message}`);
			});
	}
}
