import { Component, Input, OnInit } from '@angular/core';
import { faCheck, faCheckDouble, faClock } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { getTransformation, getTransformationFileURL } from '../../../util/fileTransformationHelper';
import { IChatConversation } from '../../models/ChatConversation.model';
import { IFile } from '../../models/File.model';
import { IMessage } from '../../models/Message.model';
import { IProfile } from '../../models/Profile.model';
import { AuthenticationService } from '../../service/authentication.service';
import { ModalService } from '../../service/modal.service';
import { ProfileService } from '../../service/profile.service';

@Component({
	selector: 'app-chat-bubble',
	templateUrl: './chat-bubble.component.html',
	styleUrls: ['./chat-bubble.component.scss']
})
export class ChatBubbleComponent implements OnInit {

	@Input()
	message: IMessage;

	@Input()
	conversation: IChatConversation;

	formattedSenderName: string;

	@Input()
	direction: 'sent' | 'received' = 'received';

	@Input()
	status: 'CREATED' | 'SENT' | 'DELIVERED' | 'SEEN';

	icons = {
		CREATED: faClock,
		SENT: faCheck,
		DELIVERED: faCheckDouble,
		SEEN: faCheckDouble
	};

	statusIcon = this.icons.CREATED;

	constructor(
		protected profileService: ProfileService,
		protected auth: AuthenticationService,
		protected modalService: ModalService
	) { }

	ngOnInit() {
		this.getSenderName();
		this.processSeenState();
		this.setStatusIcon();
	}

	getSenderName() {
		this.profileService
			.getProfile(this.message.SenderID)
			.subscribe((profile: IProfile | undefined) => {
				if (profile) {
					this.formattedSenderName = `${profile.Lastname}, ${profile.Firstname}`;
				} else {
					this.formattedSenderName = 'Gelöschter Benutzer';
				}
			});
	}

	formatDate(date: any): string {
		const now = moment();
		const messageDate = moment(date);

		switch (now.startOf('day').diff(moment(messageDate).startOf('day'), 'days')) {
			case 0:
				return `heute um ${messageDate.format('HH:mm')} Uhr`;

			case 1:
				return `gestern um ${messageDate.format('HH:mm')} Uhr`;

			case 2:
				return `vorgestern um ${messageDate.format('HH:mm')} Uhr`;

			default:
				return `${messageDate.format('DD.MM.YYYY')} um ${messageDate.format('HH:mm')} Uhr`;
		}
	}

	getImageURL(transformationType: string): string {
		if (!this.message.File || this.message.Type !== 'image') {
			return '';
		} else {
			return getTransformationFileURL(this.message.File, transformationType);
		}
	}

	getImageDimension(transformationType: string, dimension: 'width' | 'height'): string {
		const defaultValue = '500px';
		const transformation = getTransformation(this.message.File, transformationType);

		if (!transformation) {
			return defaultValue;
		}

		if (dimension === 'width') {
			return transformation.Width ? transformation.Width + 'px' : defaultValue;
		}

		if (dimension === 'height') {
			return transformation.Height ? transformation.Height + 'px' : defaultValue;
		}
	}

	openFile(file: IFile, alternativeFilename?: string): void {
		if (!file) {
			return;
		}

		const filename = alternativeFilename ? alternativeFilename : file.Filename;

		window.open(file.FileURL + '/' + encodeURIComponent(filename));
	}

	showSeenBy(): void {
		const title = 'Diese Nachricht wurde gesehen von:';

		const profiles: IProfile[] = this.profileService.getMultipleByID(this.conversation.Participants);

		const pickerOptions = [];

		profiles.forEach((profile: IProfile) => {
			if (profile.UserID === this.message.SenderID) {
				return;
			}

			let seenByCurrentUser = false;

			if (
				profile.UserID === this.auth.getUserID()
				|| this.message.SeenByUserID.includes(profile.UserID)
			) {
				seenByCurrentUser = true;
			}

			const pickerOption = {
				label: profile.Name,
				enabled: seenByCurrentUser,
				disabled: !seenByCurrentUser,
				handler: (target: any): void => {
					// Prevent the checkboxes from being unticked
					target.checked = true;
				}
			};

			pickerOptions.push(pickerOption);
		});

		this.modalService.picker(pickerOptions, title);
	}

	processSeenState(): void {
		if (this.message.SeenByUserID && this.message.SeenByUserID.length > 0) {
			const participantIDs: string[] = this.conversation.Participants;

			let seenByUserCount = 0;

			participantIDs.forEach((participantID: string) => {
				if (this.message.SeenByUserID.includes(participantID)) {
					seenByUserCount++;
				}
			});

			// -1 because the user who sent the message does not count
			if (seenByUserCount === participantIDs.length) {
				this.status = 'SEEN';
			}
		}
	}

	setStatusIcon(): void {
		if (this.status) {
			this.statusIcon = this.icons[this.status];
		} else {
			this.statusIcon = this.icons.SENT;
		}
	}
}
