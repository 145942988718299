<div content>
	<div header (tap)="navigateTo('');">
		<img logo [src]="printConfig.app_icon"><br>
		{{printConfig.name}}
		<br><br>
		<span stage-info *ngIf="serverName">{{serverName}}</span>
		<br><br>
		<div connection-info>
			Status:<br>
			<span *ngIf="(socketState$ | async) === 'connected'" style="color:green;">Verbunden</span>
			<span *ngIf="(socketState$ | async) !== 'connected'" style="color:red;">Offline</span>
		</div>
	</div>

	<div menu>
		<!-- --- -->

		<div divider>
			<span>
				<fa-icon [icon]="icons.users"></fa-icon> Benutzer
			</span>
		</div>
		<div item (tap)="navigateTo('user-list');">
			<fa-icon [icon]="icons.listUl"></fa-icon> <label>Benutzer</label>
		</div>
		<div item (tap)="navigateTo('user-create');">
			<fa-icon [icon]="icons.plusCircle"></fa-icon> <label>Benutzer erstellen</label>
		</div>

		<!-- --- -->

		<div divider>
			<span>
				<fa-icon [icon]="icons.comments"></fa-icon> CHAT
			</span>
		</div>
		<div item (tap)="navigateTo('chat-group-list');">
			<fa-icon [icon]="icons.listUl"></fa-icon> <label>Gruppen</label>
		</div>
		<div item (tap)="navigateTo('chat-group-create');">
			<fa-icon [icon]="icons.plusCircle"></fa-icon> <label>Gruppe erstellen</label>
		</div>
		<div item (tap)="navigateTo('chat-broadcast-list');">
			<fa-icon [icon]="icons.bcList"></fa-icon><label>Verteilerlisten</label>
		</div>
		<div item (tap)="navigateTo('chat-broadcast-create');">
			<fa-icon [icon]="icons.plusCircle"></fa-icon> <label>Verteilerliste erstellen</label>
		</div>

		<!-- --- -->
		<div divider>
			<span>
				<fa-icon [icon]="icons.legal"></fa-icon> Rechtliches
			</span>
		</div>
		<div item (tap)="navigateTo('user-agreement-list');">
			<fa-icon [icon]="icons.agreements"></fa-icon> <label>Vereinbarungen</label>
		</div>
		<div item (tap)="navigateTo('user-agreement-create');">
			<fa-icon [icon]="icons.plusCircle"></fa-icon> <label>Vereinbarung erstellen</label>
		</div>

		<!-- --- -->

		<div divider>
			<span>
				<fa-icon [icon]="icons.info"></fa-icon> Hilfe
			</span>
		</div>
		<div item (tap)="openDocs();">
			<fa-icon [icon]="icons.docs"></fa-icon> <label>Dokumentation</label>
		</div>
		<div item (tap)="supportEmail();">
			<fa-icon [icon]="icons.email"></fa-icon> <label>Support E-Mail</label>
		</div>

		<!-- --- -->

		<div divider>
			<span>
				<fa-icon [icon]="icons.cogs"></fa-icon> Weiteres
			</span>
		</div>
		<div item (tap)="navigateTo('system');">
			<fa-icon [icon]="icons.wrench"></fa-icon> <label>System</label>
		</div>
		<div item (tap)="logout();">
			<fa-icon [icon]="icons.powerOff"></fa-icon> <label>Ausloggen</label>
		</div>

	</div>
</div>