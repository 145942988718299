import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Subscription } from 'rxjs/Subscription';

import { StorageService } from './storage.service';

interface IStore {
	id: string;
	data$: BehaviorSubject<any>;
	subscription?: Subscription;
}

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	protected stores: IStore[] = [];

	constructor(
		protected storageProvider: StorageService
	) {}

	getSubject<T>(id: string, initValue: T = null, preInitUnedfined?: true): BehaviorSubject<T> {
		const store = this.findByID(id);

		if (store) {
			return store.data$;
		}

		return this.createStore<T>(id, initValue, preInitUnedfined).data$;
	}

	remove(id: string): void {
		const index: number = this.stores.findIndex((_store: IStore): boolean => {
			return _store.id === id;
		});

		if (index === -1) {
			return;
		}

		// Remove persisted data from the store
		this.storageProvider.remove(id);

		// Unsubscribe sync
		const store = this.stores[id];

		if (store.subscription) {
			store.subscription.unsubscribe();
		}

		// Remove the store from the internal array
		this.stores.splice(index, 1);
	}

	protected findByID(id: string): IStore {
		return this.stores.find(((store: IStore): boolean => {
			return store.id === id;
		}));
	}

	protected createStore<T>(id: string, initValue: T, preInitUnedfined: boolean): IStore {
		const _initValue = preInitUnedfined ? undefined : initValue;

		const data$ = new BehaviorSubject<T>( _initValue );

		const newStore: IStore = {
			id,
			data$
		};

		this.stores.push(newStore);

		const storageData = this.storageProvider.get(`store:${id}`);
		// For newly created storages, prevent overwriting the initValue with null, if there's no data in the storage yet.
		if (storageData !== null) {
			data$.next(storageData);
		} else {
			if (preInitUnedfined) {
				data$.next(initValue);
			}
		}

		// Start the sync afterwards.
		// Otherwise we'll end up in an infinite loop
		this.keepInSync(newStore);

		return newStore;
	}

	protected keepInSync(store: IStore): void {
		store.subscription = store.data$.subscribe((data: any) => {
			this.storageProvider.set(`store:${store.id}`, data);
		});
	}

}
