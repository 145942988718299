import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { combineLatest } from 'rxjs/observable/combineLatest';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { objectsAreEqual } from '../../util/object';
import { APIConfig } from '../models/Config.model';
import { ILogin } from '../models/Login.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { IUser } from '../models/User.model';
import { ConfigService } from './config.service';
import { IConnectionState, SocketService } from './socket.service';
import { StoreService } from './store.service';


@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {

	public token$: BehaviorSubject<string> = this.store.getSubject<string>('auth:token', null, true);
	public authState$: BehaviorSubject<boolean> = this.store.getSubject<boolean>('auth:state', null);
	public socketAuthState$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public user$: BehaviorSubject<IUser> = this.store.getSubject<IUser>('auth:user');

	constructor(
		private socket: SocketService,
		private store: StoreService,
		private config: ConfigService
	) {
		this.bindSocketListeners();

		this.handleSessionManagement();

		this.forceLogoutIfLoggedInButNoAPIConfig();
	}

	handleSessionManagement(): void {
		// If token isset, connection is established, and socketAuthState not true yet, validate the session token
		combineLatest(this.token$, this.socket.connectionState$, this.socketAuthState$)
			.pipe(
				distinctUntilChanged((prev: [string, IConnectionState, boolean], next: [string, IConnectionState, boolean]): boolean => {
					return objectsAreEqual(prev, next);
				}),
				filter((next: [string, IConnectionState, boolean]) => {
					const token: string = next[0];
					const connectionState: IConnectionState = next[1];
					const socketAuthState = next[2];

					return !!token && connectionState === 'connected' && !socketAuthState;
				}),
				map((next: [string, IConnectionState, boolean]) => {
					const token: string = next[0];

					// FIXME: LEGACY FIX. REMOVE SOON! (Juli 2019) Also remove IToken type
					// tslint:disable-next-line: no-string-literal
					if (typeof token !== 'string' && token['Token']) {
						// tslint:disable-next-line: no-string-literal
						return token['Token'];
					}

					return token;
				})
			)
			.subscribe((token: string) => {
				this
					.validateToken(token)
					.subscribe(
						// Token is valid
						(event: ISocketEvent) => {
							this.onToken(token);
						},
						// Token is invalid
						(err: any) => {
							// Ignore timeout and connection loss
							if (err.status && err.status !== 900 && err.status !== 408) {
								this.onLogout();
							}

							if (err.status && err.status === 423) {
								alert('Hinweis: Dieser Account wurde gesperrt. Ein Login ist nicht möglich.');
							}
						});
			});

		// If general authstate is true, but there is no token: force logout
		combineLatest(this.authState$, this.token$)
			.pipe(
				distinctUntilChanged((prev: [boolean, string], next: [boolean, string]): boolean => {
					return objectsAreEqual(prev, next);
				})
			)
			.subscribe((state: [boolean, string]) => {
				const authState = state[0];
				const token = state[1];

				if (authState === true && token === null) {
					this.onLogout();
				}
			});
	}

	bindSocketListeners(): void {
		// Logout Event //
		this.socket
			.observe('auth:logout')
			.subscribe(() => {
				this.onLogout();
				this.socketAuthState$.next(false);
			});

		// Login Event //
		this.socket
			.observe('auth:success')
			.subscribe((event: ISocketEvent) => {
				this.setUser(event.payload);
				this.socketAuthState$.next(true);
			});

		// Token Event //
		this.socket
			.observe('auth:token')
			.subscribe((event: ISocketEvent) => {
				this.onToken(event.payload);
			});

		// In case of connection loss, recheck the token, when connection is back.
		this.socket
			.connectionState$
			.subscribe((state: 'connected' | 'disconnected') => {
				if (state === 'disconnected') {
					this.socketAuthState$.next(false);
				}
			});
	}

	protected setUser(userData: IUser): void {
		this.user$.next(userData);
	}

	protected unsetUser(): void {
		this.user$.next(null);
	}

	protected setToken(token: string): void {
		// FIXME: This line has been introduced, because the app randomly logged out without it.
		// There seems to be a caller, calling this function with undefined instead of a string...
		if (!token) {
			return;
		}

		this.token$.next(token);
	}

	protected unsetToken(): void {
		this.token$.next(null);
	}

	getUserID(): string {
		const user: IUser = this.user$.getValue();

		return user ? user._id : null;
	}

	login(loginData: ILogin): Observable<ISocketEvent> {
		return this.socket.ensureConnection(
			this.socket
				.pullRequest(
					// sendEventName
					'auth:login',
					// sendPayload
					loginData,
					// successEventName
					'auth:success',
					// failEventName
					'auth:fail'
				)
		);
	}

	validateToken(token: string): Observable<ISocketEvent> {
		return this.socket
			.pullRequest(
				// sendEventName
				'auth:token:validate',
				// sendPayload
				{ Token: token },
				// successEventName
				'auth:token:validate:success',
				// failEventName
				'auth:token:validate:fail'
			);
	}

	updatePassword(newPassword: string): Observable<ISocketEvent> {
		return this.socket
			.pullRequest(
				// sendEventName
				'auth:password:update',
				// sendPayload
				{ Password: newPassword },
				// successEventName
				'auth:password:update:success',
				// failEventName
				'auth:password:update:fail'
			);
	}

	forgotPassword(Email: string): Observable<ISocketEvent> {
		return this.socket
			.pullRequest(
				// sendEventName
				'auth:password:forgot',
				// sendPayload
				{ Email },
				// successEventName
				'auth:password:forgot:success',
				// failEventName
				'auth:password:forgot:fail',
				// Timeout in ms
				12 * 1000
			);
	}

	logout(): void {
		this.socket
			.send('auth:logout');

		// TODO: Force logout after a few seconds. Show loading until then
	}

	onToken(token: string): void {
		this.setToken(token);
		this.authState$.next(true);
	}

	onLogout(): void {
		this.unsetToken();
		this.unsetUser();
		this.authState$.next(false);
	}

	protected forceLogoutIfLoggedInButNoAPIConfig(): void {
		this.authState$
			.subscribe((authState: boolean) => {
				if (authState && this.config.apiConfig$.getValue() === null) {
					this.onLogout();
				}
			});

		this.config.apiConfig$
			.subscribe((apiConfig: APIConfig) => {
				const authState = this.authState$.getValue();

				if (authState && apiConfig === null) {
					this.onLogout();
				}
			});
	}

}
