<div page page-chat-user-agreement-list>
	<div class="card">
		<div class="card-header">
			<fa-icon [icon]="icons.agreement"></fa-icon> Vereinbarungen
		</div>
		<div class="card-body">
			<div class="content">
				<div class="btn btn-warning" (tap)="refresh()"><fa-icon [icon]="icons.refresh"></fa-icon>&nbsp;&nbsp; Aktualisieren</div><br><br>
			</div>
			<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
				<div class="row">
					<div class="col-12">
						<form class="form-inline">
							<label class="sr-only" for="inlineFormInputGroupUsername2">Suche</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
								<div class="input-group-text"><fa-icon [icon]="icons.search"></fa-icon></div>
								</div>
								<input type="text" class="form-control" name="searchTerm" placeholder="Suche" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}">
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-striped table-bordered table-hover datatable dataTable no-footer" role="grid" style="border-collapse: collapse !important">
							<thead>
								<tr role="row">
									<th tabindex="0" rowspan="1" colspan="1">Titel</th>
									<th tabindex="0" rowspan="1" colspan="1">Erstellt am</th>
									<th tabindex="0" rowspan="1" colspan="1">Aktualisiert am</th>
									<th tabindex="0" rowspan="1" colspan="1">Aktionen</th>
								</tr>
							</thead>
							<tbody>
								<tr role="row" class="odd clickable" *ngFor="let agreement of filter(searchTerm, (agreements$ | async))" (click)="navigateToDetail(agreement)">
									<td class="sorting_1">{{agreement.Title}}</td>
									<td>{{formatDate(agreement.CreateDate)}}</td>
									<td>
										{{formatDate(agreement.LastUpdate)}}
									</td>
									<td class="text-center">
										<div class="btn btn-danger" (click)="$event.stopPropagation(); delete(agreement);">
											<fa-icon [icon]="icons.trash"></fa-icon>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>