<div class="modal-header">
	<h5 class="modal-title">{{title}}</h5>
	<button type="button" class="close" aria-label="Close" (tap)="abort();">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p [innerHTML]="description"></p>
	<form #promptForm="ngForm" novalidate>
		<div class="form-group">
			<input type="text" class="form-control" [placeholder]="placeholder" [(ngModel)]="value" #valueField="ngModel" [ngClass]="{'is-invalid': !validate() && valueField.touched}" name="value">
		</div>
	</form>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (tap)="close(value);" [disabled]="!validate()">{{buttonLabels.okay}}</button>
	<button type="button" class="btn btn-danger" (tap)="abort();">{{buttonLabels.abort}}</button>
</div>
	