import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faFileExclamation, faHandshake, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserAgreementService } from 'src/app/service/user-agreement.service';

@Component({
	selector: 'app-page-user-agreement-create',
	templateUrl: './page-user-agreement-create.component.html',
	styleUrls: ['./page-user-agreement-create.component.scss']
})
export class PageUserAgreementCreateComponent implements OnInit {

	// VIEW CHILDREN //
	@ViewChild('createForm')
	form: NgForm;

	icons = {
		info: faInfoCircle,
		agreement: faHandshake,
		document: faFileExclamation
	};

	newAgreement = {
		Title: '',
		Html: ''
	};

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: false,
		height: '40vh',
		sanitize: false,
		toolbarHiddenButtons: [
			['insertImage', 'insertVideo']
		]
	};

	constructor(
		protected router: Router,
		protected agreementService: UserAgreementService,
		protected spinner: NgxSpinnerService,
	) { }

	ngOnInit() {
	}

	protected onSuccess() {
		this.router.navigate(['']);
	}

	reset(): void {
		this.newAgreement = {
			Title: '',
			Html: ''
		};

		this.form.reset();
	}

	submit(): void {
		this.spinner.show();

		this.agreementService
			.create(this.newAgreement.Title, this.newAgreement.Html)
			.subscribe(() => {
				// success
				this.spinner.hide();
				alert(`Erfolg! Die Vereinbarung "${this.newAgreement.Title}" wurde erstellt.`);
				this.reset();
			}, (err) => {
				this.spinner.hide();
				alert(`Fehler: Die Vereinbarung konnte nicht erstellt werden. Code: ${err.status} - ${err.message}`);
			});
	}

}
