import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CroppieOptions } from 'croppie';

import { NgxCroppieComponent } from '../ngx-croppie/ngx-croppie.component';

@Component({
	selector: 'app-avatar',
	templateUrl: './avatar.component.html',
	styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
	@Input()
	set src(url: string) {
		this._src = url;

		this.resetImgSrc();
	}

	@Input()
	placeholderSrc: string = 'assets/default_avatar.svg';

	@Input()
	changeable: boolean = false;

	imgSrc: string;

	imgSrcForCroppie: string;

	@ViewChild('croppie')
	ngxCroppie: NgxCroppieComponent;

	imageFromCroppieBase64: string;

	// Outputs
	@Output()
	result = new EventEmitter<string>();

	croppieOptions: CroppieOptions = {
		viewport: {
			height: 190,
			width: 190,
			type: 'circle'
		},
		boundary: {
			width: 200,
			height: 200
		}
	};

	outputSize = { height: 1000, width: 1000 };

	imgLoaded: boolean = false;

	_src: string;

	constructor(
		protected modalService: NgbModal
	) { }

	ngOnInit() {
		this.resetImgSrc();
	}

	resetImgSrc() {
		if (!this._src) {
			this.imgSrc = this.placeholderSrc;
			this.imgSrcForCroppie = null;
		} else {
			this.imgSrc = this._src;
			this.imgSrcForCroppie = this._src;
		}
	}

	onAvatarClick(modalContentTemplate) {
		if (!this.changeable) {
			return;
		}

		this.modalService.open(modalContentTemplate, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
			// Close
			// this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			// Dimiss
			// this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	onLoad(event) {
		this.imgLoaded = true;
	}

	save() {
		if (this.imageFromCroppieBase64) {
			this.result.emit(this.imageFromCroppieBase64);
		}
	}

	abort() {
		this.resetImgSrc();
		this.imageFromCroppieBase64 = null;
	}

	// For image upload
	onFileChanged(event) {
		if (!event.target || !event.target.files || event.target.files.length !== 1) {
			return;
		}

		const file = event.target.files[0];

		if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/jpg') {
			return;
		}

		const fr = new FileReader();

		fr.onloadend = (loadEvent) => {
			this.imgSrcForCroppie = fr.result as string;
		};

		fr.readAsDataURL(file);
	}

	newImageResultFromCroppie(imageBase64: any) {
		this.imageFromCroppieBase64 = imageBase64;
	}

}
