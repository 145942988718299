import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { timer } from 'rxjs/observable/timer';
import { Observer } from 'rxjs/Observer';
import { flatMap } from 'rxjs/operators';
import { filter } from 'rxjs/operators/filter';
import { map } from 'rxjs/operators/map';
import { merge } from 'rxjs/operators/merge';
import { take } from 'rxjs/operators/take';
import { Subject } from 'rxjs/Subject';
import { Subscription } from 'rxjs/Subscription';
import { io, Manager, ManagerOptions, Socket, SocketOptions } from 'socket.io-client';
import * as wildcard from 'socketio-wildcard';

import { APIConfig } from '../models/Config.model';
import { SocketEventModel as Event, ISocketEvent, SocketEventModel } from '../models/SocketEvent.model';
import { ConfigService } from './config.service';
import { StoreService } from './store.service';

const wilcardPatch = wildcard(Manager);

export type IConnectionState = 'connected' | 'disconnected';

@Injectable({
	providedIn: 'root'
})
export class SocketService {

	protected socket: Socket = null;
	protected socketSubject$ = new Subject<ISocketEvent>();
	public connectionState$ = new BehaviorSubject<IConnectionState>('disconnected');
	public latency$ = new BehaviorSubject<number>(-1); // in microseconds
	protected selectedAPIname$ = this.store.getSubject<string>('socket:selectedApiName', null);
	public APIConfig$ = this.config.apiConfig$;

	protected subsctiptions: Subscription[] = [];

	// This queue is only used if the this.socket object is not created yet.
	// It does NOT handle loss off connection ('disconnected' state).
	protected emitQueue: any[] = [];

	protected nativeEvents = [
		// Fired upon a connection including a successful reconnection.
		'connect',

		// Fired upon a connection error.
		'connect_error',

		// Fired upon a connection timeout.
		'connect_timeout',

		// Fired upon a connection timeout.
		// - reason (String) either 'io server disconnect' or 'io client disconnect'
		'disconnect',

		// Fired upon a successful reconnection.
		// - attempt (Number) reconnection attempt number
		'reconnect',

		// Fired upon an attempt to reconnect.
		// - attempt (Number) reconnection attempt number
		'reconnecting',

		// Fired upon an attempt to reconnect.
		// - attempt (Number) reconnection attempt number
		'reconnect_attempt',

		// Fired upon a reconnection attempt error.
		// - error (Object) error object
		'reconnect_error',

		// Fired when couldn't reconnect within reconnectionAttempts.
		'reconnect_failed',

		// Fired when an error occurs.
		// - error (Object) error object
		'error',

		// Fired when a ping packet is written out to the server.
		'ping',

		// Fired when a pong is received from the server.
		// - ms (Number) number of ms elapsed since ping packet (i.e.: latency).
		'pong'
	];

	constructor(
		protected config: ConfigService,
		protected store: StoreService
	) {
		this.initAPIConfig();
	}

	/**
	 * This method starts the socket service.
	 * 1. Check if selectedAPI$ is not null
	 * 2. If it is not null:
	 * 3. Establish connection
	 */
	init(): void {
		// TODO: Reset App State if Selected API os not available.

		this.observeConnectionState();
		this.verboseDebug();
	}

	connect(apiConfig: APIConfig): Socket {
		// Abort if there is no config given
		if (apiConfig === null || !apiConfig) {
			return;
		}

		// If there already is a socket open, close and remove it first
		if (this.socket !== null) {
			this.disconnect();
		}

		// Prepare socket parameters
		const protocol = this.config.mainConfig.ssl ? 'wss' : 'ws';
		const port = apiConfig.port ? ':' + apiConfig.port : '';

		const url = `${protocol}://${apiConfig.host}${port}`;

		const options: Partial<ManagerOptions & SocketOptions> = {};

		if (apiConfig.path) {
			options.path = apiConfig.path;
			options.query = {
				'socketio-version': '4'
			};
		}

		// Connect to server
		const socket = io(url, options);

		this.connectionState$.next('disconnected');

		// Patch the socket in-place
		wilcardPatch(socket);

		this.socket = socket;

		this.observeNativeSocketEvents(this.nativeEvents);
		this.observeSocketEvents();

		return socket;
	}

	reconnect(): void {
		if (!!this.socket) {
			this.socket.open();
		}
	}

	disconnect(): void {
		if (!!this.socket) {
			this.socket.close();
			this.socket = null;
		}
	}

	send(eventName: string, payload?: any, ack?: () => void): void {
		if (!this.socket) {
			if (this.isVerbose()) {
				// tslint:disable-next-line:no-console
				console.log('[WARNING]: Socket not available. Storing event in queue for later emission.', eventName, payload);
			}

			this.emitQueue.push({
				eventName,
				payload,
				ack
			});

			return;
		}

		if (this.isVerbose()) {
			// tslint:disable-next-line:no-console
			console.log('[EMITTING EVENT]', eventName, payload);
		}

		this.socket.emit(
			eventName,
			payload,
			ack
		);
	}

	observe(eventName: string): Observable<ISocketEvent> {
		return this.socketSubject$
			.pipe(filter((incomingEvent: ISocketEvent) => {
				return incomingEvent.eventName === eventName;
			}));
	}

	observeOnce(eventName: string): Observable<ISocketEvent> {
		return this.observe(eventName).pipe(take(1));
	}

	protected observeConnectionState(): void {
		const connectionState$ = this.connectionState$;

		const connect = this.observe('connect');
		const disconnect = this.observe('disconnect');

		// initial value
		of(new Event('disconnect'))
			.pipe(
				// Connect value
				merge(connect),
				// Disconnect value
				merge(disconnect),
				// Map eventname to bool value
				map((e: ISocketEvent) => {
					return e.eventName === 'connect' ? 'connected' : 'disconnected';
				})
			)
			// Apply to class context
			.subscribe((connectionState: IConnectionState) => {
				connectionState$.next(connectionState);

				if (this.isVerbose()) {
					// tslint:disable-next-line:no-console
					console.log('Connection State:', connectionState);
				}

				if (connectionState === 'connected' && this.emitQueue.length > 0) {
					if (this.isVerbose()) {
						// tslint:disable-next-line:no-console
						console.log('=== SOCKET BACK ONLINE; EMITTING QUEUE ===');
					}

					while (this.emitQueue.length > 0) {
						const event = this.emitQueue.pop();

						if (this.isVerbose()) {
							// tslint:disable-next-line:no-console
							console.log('[EMITTING EVENT]', event.eventName, event.payload);
						}

						this.send(event.eventName, event.payload, event.ack);
					}
				}
			});

		this.observe('pong')
			.subscribe((event: ISocketEvent) => {
				// In the payload you'll find the latency in ms
				this.latency$.next(event.payload);
			});
	}

	ensureConnection<T>(obs: Observable<T>): Observable<T> {
		return this.connectionState$
			.pipe(
				filter((state: IConnectionState) => {
					return state === 'connected';
				}),
				take(1),
				flatMap(() => {
					return obs;
				})
			);
	}

	protected initAPIConfig(): void {
		this.APIConfig$.subscribe((apiConfig: APIConfig) => {
			if (apiConfig !== null) {
				this.connect(apiConfig);
			} else {
				this.disconnect();
			}
		});
	}

	public setAPIConfig(apiConfig: APIConfig): void {
		/*this.selectedAPIname$.next(apiConfig.name);
		this.selectedAPI$.next(apiConfig);*/
	}

	protected observeSocketEvents(): void {
		this.socket.on('*', (packet: any) => {
			const eventName = packet.data[0];
			const payload = packet.data[1];

			this.socketSubject$.next(new Event(eventName, payload));
		});
	}

	protected observeNativeSocketEvents(nativeEventNames: string[]): void {
		nativeEventNames.forEach((eventName: string) => {
			this.socket.on(eventName, (data: any) => {
				this.socketSubject$.next(new Event(eventName, data));
			});
			this.socket.io.on(eventName as any, (data: any) => {
				this.socketSubject$.next(new Event(eventName, data));
			});
		});
	}

	protected isVerbose(): boolean {
		return this.config.mainConfig.verbose;
	}

	protected verboseDebug(): void {
		this.socketSubject$.subscribe((event: ISocketEvent) => {
			if (!this.isVerbose()) {
				return;
			}

			// tslint:disable-next-line:no-console
			console.log('[SOCKET EVENT]', event);
		});
	}

	pullRequest(
		sendEventName: string,
		sendPayload: any,
		successEventName: string,
		failEventName: string,
		timeout: number = null
	): Observable<ISocketEvent> {
		return Observable.create((observer: Observer<any>) => {
			const apiConfig = this.APIConfig$.getValue();
			const success = this.observe(successEventName);
			const failure = this.observe(failEventName);

			if (timeout === null && !!apiConfig) {
				timeout = this.config.mainConfig.timeout;
			}

			// Check connection state first. If there is no connection, do not execeute.
			if (this.connectionState$.getValue() === 'disconnected') {
				const errorEvent = {
					code: 901, // Custom error code
					error: true,
					message: `There is no connection for transmission. (EventName: '${sendEventName}')`,
					name: 'No connection',
				};

				// this.monitoring.error(`There is no connection for transmission. (EventName: '${sendEventName}')`, errorEvent);

				const socketErrorEvent = new SocketEventModel(failEventName, errorEvent);

				observer.error(socketErrorEvent);

				// Abort execution
				return;
			}

			const connectionState: Observable<ISocketEvent> = this.connectionState$.pipe(
				filter((state: IConnectionState): boolean => {
					return state === 'disconnected';
				}),
				map((state: IConnectionState): ISocketEvent => {
					const errorEvent = {
						code: 900, // Custom error code
						error: true,
						message: `Connection to the server was lost during transmission. (EventName: '${sendEventName}')`,
						name: 'Connection lost',
					};

					// this.monitoring.error(`Lost connection during pull request '${sendEventName}'`, errorEvent);

					return new SocketEventModel(failEventName, errorEvent);
				})
			);

			const timeoutState: Observable<ISocketEvent> = timer(timeout)
				.pipe(
					take(1),
					map((): ISocketEvent => {
						const errorEvent = {
							code: 408,
							error: true,
							message: `Request Timeout, (Timeout in ms: ${timeout}, EventName: '${sendEventName}')`,
							name: 'Request Timeout',
						};

						// this.monitoring.error(`Pull request '${sendEventName}' timed out`, errorEvent);
						return new SocketEventModel(failEventName, errorEvent);
					})
				);

			const response = success.pipe(merge(failure), merge(connectionState), merge(timeoutState), take(1));

			response
				.pipe(
					map((event: ISocketEvent) => {
						if (event.eventName === successEventName) {
							return event;
						} else {
							throw event.payload;
						}
					})
				)
				.subscribe((result: ISocketEvent) => {
					observer.next(result);
					observer.complete();
				}, (err: any) => {
					observer.error(err);
				});

			this.send(sendEventName, sendPayload);
		});
	}

	waitForConnection(): Promise<void> {
		return new Promise((resolve) => {
			this.connectionState$
				.pipe(
					filter((state) => {
						return state === 'connected';
					}),
					take(1)
				).subscribe(() => {
					resolve();
				});
		});
	}

}
