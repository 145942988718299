import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { filter, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { objectsAreEqual } from '../../util/object';
import { APIConfig, IConfig, IPrintInfo } from '../models/Config.model';
import { ModalService } from './modal.service';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root'
})
export class ConfigService {
	protected masterServerURL: string = environment.masterServerURL;
	public readonly predefinedCode: string = environment.predefinedCode;

	// tslint:disable-next-line: max-line-length
	public readonly serverCode$: BehaviorSubject<string> = this.storeProvider.getSubject<string>('config:serverCode', this.predefinedCode, true);
	public readonly apiConfig$: BehaviorSubject<APIConfig> = this.storeProvider.getSubject<APIConfig>('config:server', null, true);

	constructor(
		protected storeProvider: StoreService,
		protected httpClient: HttpClient,
		protected modalService: ModalService
	) {
		this.initApiConfig();
	}

	public readonly mainConfig: IConfig = {
		verbose: true,
		ssl: environment.ssl,
		timeout: 65 * 1000, // ms
	};

	public readonly printConfigBico: IPrintInfo = {
		name: 'bico App',
		web_url_prefix: 'https://bico-app.de',
		appStore_url: 'https://itunes.apple.com/us/app/bico-app/id1447496735',
		playStore_url: 'https://play.google.com/store/apps/details?id=com.burningparrot.bico',
		print_logo: 'assets/print/bico_logo.svg',
		app_icon: 'assets/app_icon_bico.jpg',
		qr_code: 'assets/print/bico_qr_code.png',
		showServerCodeInPrint: true
	};

	public readonly printConfigEE: IPrintInfo = {
		name: 'EE App',
		web_url_prefix: 'https://ee-app.de',
		appStore_url: 'https://itunes.apple.com/de/app/ee-app/id1393211802?mt=8',
		playStore_url: 'https://play.google.com/store/apps/details?id=com.burningparrot.eeapp',
		print_logo: 'assets/print/ee_logo.svg',
		app_icon: 'assets/app_icon_ee.jpg',
		qr_code: 'assets/print/ee_qr_code.png',
		showServerCodeInPrint: false
	};

	public getPrintInfo(): IPrintInfo {
		const serverCode = this.serverCode$.getValue();

		if (serverCode === 'EELIVE') {
			return this.printConfigEE;
		}

		return this.printConfigBico;
	}

	protected initApiConfig(): void {
		this.apiConfig$
			.pipe(
				// Wait until the subject is loaded from store
				filter((config: APIConfig) => {
					return config !== undefined;
				}),
				take(1)
			).subscribe(async () => {
				const currentCode = this.serverCode$.getValue();
				try {
					// Init predefined server code
					if (this.predefinedCode) {
						this.setCode(this.predefinedCode);
						await this.retrieveConfig(this.predefinedCode);
					} else if (currentCode) {
						await this.retrieveConfig(currentCode);
					}
				} catch (err) {

				}
			});
	}
	/// CODE ///
	setCode(serverCode: string): void {
		this.serverCode$.next(serverCode);
	}

	unsetCode(): void {
		this.serverCode$.next('');
		this.setAPIConfig(null);
	}

	isSetCode(): boolean {
		return this.serverCode$.getValue() !== '';
	}

	/// CONFIG ///
	async retrieveConfig(serverCode: string, noRetry: boolean = false, handleErrorResponse: boolean = true): Promise<APIConfig> {
		const url = this.masterServerURL + serverCode;

		try {
			const config = await this.httpClient.get<APIConfig>(url).toPromise();

			this.setAPIConfig(config);
			this.setCode(serverCode);

			return config;
		} catch (err) {
			if (handleErrorResponse) {
				this.handleErrorResponse(err);
			}

			if (err.status === 0) {
				if (!noRetry) {
					if (this.mainConfig.verbose) {
						// tslint:disable-next-line: no-console
						console.info('Machine seems to be offline. Try again in 3 seconds.');
					}

					setTimeout(async () => {
						try {
							await this.retrieveConfig(serverCode);
						} catch (_err) {
							// Ignore
							this.handleErrorResponse(_err);
						}
					}, 3 * 1000);
				} else {
					throw err;
				}
			} else {
				throw err;
			}
		}
	}

	setAPIConfig(config: APIConfig): void {
		const currentConfig = this.apiConfig$.getValue();

		if (objectsAreEqual(config, currentConfig)) {
			return;
		}

		this.apiConfig$.next(config);
	}

	unsetAPIConfig(): void {
		this.unsetCode();

		if (this.mainConfig.verbose) {
			// tslint:disable-next-line: no-console
			console.info('Unsetting API Config');
		}
	}

	protected handleErrorResponse(err: any): void {
		if (this.mainConfig.verbose) {
			// tslint:disable-next-line: no-console
			console.error('Error while retrieving config from master', err);
		}

		if (err.status && err.status === 404) {
			// Server code is invalid. Show alert and unset apiConfig$ (will trigger logout in auth provider)

			this.showAlert(
				'Hinweis',
				`
					Der Verbindungscode "${this.serverCode$.getValue()}" ist nicht mehr gültig.<br><br>
					Bitte wenden Sie sich an ihren Administrator.<br>
					<br>
					Sie werden ausgeloggt.
				`
			);

			this.unsetAPIConfig();
			return;
		}

		// tslint:disable-next-line: no-console
		console.error('Unhandled error during apiConfig re-fetch', err);
	}

	protected showAlert(title: string, text: string): void {
		const alert = this.modalService.alert(
			title,
			text
		);

		alert.subscribe();
	}

}
