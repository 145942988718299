import { IFileTransformation, IFile } from '../app/models/File.model';

export function getTransformationFileURL(file: IFile, type: string): string {

	if (file) {
		if (file.Transformations) {
			const transformation_found = getTransformation(file, type);

			if (transformation_found) {
				return transformation_found.FileURL;
			}

		}

		return file.FileURL;
	}

	return '';
}

export function getTransformation(file: IFile, type: string): IFileTransformation {

	if (file) {
		if (file.Transformations) {
			const transformation_found = file.Transformations.find((transformation: IFileTransformation) => {
				return transformation.Type === type;
			});

			if (transformation_found) {
				return transformation_found;
			}

		}
	}

	return ;
}
