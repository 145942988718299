<div class="flex-row align-items-center full-viewport-height">
	<div class="row justify-content-center">
		<div class="col-md-4">
			<div class="card-group">
				<div class="card p-4">
					<div class="card-body">
						<form #loginForm="ngForm" novalidate>
							<h1>Login</h1>
							<p class="text-muted">Der Login ist nur Administratoren gestattet.</p>
							<div class="input-group mb-3" *ngIf="config.predefinedCode === ''">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<fa-icon [icon]="icons.key"></fa-icon>
									</span>
								</div>
								<input type="text" server-code class="form-control" placeholder="Verbindungscode" [(ngModel)]="serverCode" name="serverCode" #serverCodeField="ngModel" [ngClass]="{'is-invalid': serverCodeField.invalid &&  serverCodeField.touched}" minlength="1" (keyup.enter)="applyServerCode();" required>
							</div>
							<div class="input-group mb-3">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<fa-icon [icon]="icons.user"></fa-icon>
									</span>
								</div>
								<input type="text" username class="form-control" placeholder="Benutzername" [(ngModel)]="username" name="username" #usernameField="ngModel" [ngClass]="{'is-invalid': usernameField.invalid &&  usernameField.touched}" minlength="3" required>
							</div>
							<div class="input-group mb-4">
								<div class="input-group-prepend">
									<span class="input-group-text">
										<fa-icon [icon]="icons.unlock"></fa-icon>
									</span>
								</div>
								<input type="password" class="form-control" placeholder="Password" [(ngModel)]="password" name="password" #passwordField="ngModel" [ngClass]="{'is-invalid': passwordField.invalid &&  passwordField.touched}" minlength="5" required>
							</div>
							<div class="row">
								<div class="col-12 text-center">
									<button (click)="login();" type="submit" class="btn btn-primary px-4" [disabled]="loginForm.invalid">Einloggen</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>