<div page page-broadcast-list>
	<div class="card">
		<div class="card-header">
			<fa-icon [icon]="icons.bcList"></fa-icon> Verteilerlisten
		</div>
		<div class="card-body">
			<div class="content">
				<div class="btn btn-warning" (tap)="refresh()">
					<fa-icon [icon]="icons.refresh"></fa-icon>&nbsp;&nbsp; Aktualisieren
				</div><br><br>
			</div>
			<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
				<div class="row">
					<div class="col-12">
						<form class="form-inline">
							<label class="sr-only" for="inlineFormInputGroupUsername2">Suche</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
									<div class="input-group-text">
										<fa-icon [icon]="icons.search"></fa-icon>
									</div>
								</div>
								<input type="text" class="form-control" name="searchTerm" placeholder="Suche" [(ngModel)]="searchTerm"
									[ngModelOptions]="{standalone: true}">
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-striped table-bordered table-hover datatable dataTable no-footer" role="grid"
							style="border-collapse: collapse !important">
							<thead>
								<tr role="row">
									<th tabindex="0" rowspan="1" colspan="1">Titel</th>
									<th tabindex="0" rowspan="1" colspan="1">Erstellt am</th>
									<th tabindex="0" rowspan="1" colspan="1">Empfänger</th>
								</tr>
							</thead>
							<tbody>
								<tr role="row" class="odd clickable" *ngFor="let bcList of filter(searchTerm, (bcLists$ | async))"
									(tap)="navigateToBroadcastDetail(bcList)">
									<td class="sorting_1">{{bcList.Title}}</td>
									<td>{{formatDate(bcList.CreateDate)}}</td>
									<td>
										{{bcList.ReceiverIDs.length}}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>