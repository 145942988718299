<div page page-broadcast-detail *ngIf="bcList$ | async">
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Details
				</div>
				<div class="card-body">
					<div section>
						<strong>Titel:</strong><br>
						{{(bcList$ | async).Title}}
					</div>
					<div section>
						<strong>Erstellungsdatum:</strong><br>
						{{formatDate((bcList$ | async).CreateDate)}}
					</div>
					<div section>
						<strong>Empfängeranzahl:</strong><br>
						{{(bcList$ | async).ReceiverIDs.length}}
					</div>
					<div section>
						<strong>Datenbank ID:</strong><br>
						{{bcListID}}
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Empfänger
				</div>
				<div class="card-body">
					<div class="btn btn-primary" (tap)="editReceivers();">Bearbeiten</div>
					<br><br>
					<ul class="list-group" participant-list>
						<li class="list-group-item" *ngFor="let receiver of (receiverProfiles$ | async)">
							<img avatar [src]="getAvatarURL(receiver)">{{receiver.Lastname}}{{receiver.Lastname && receiver.Firstname ? ', ': ''}}{{receiver.Firstname}}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Administration
				</div>
				<div class="card-body">
					<div class="btn btn-primary btn-block" (tap)="editTitle();">Titel ändern</div>
					<div class="btn btn-danger btn-block" (tap)="remove();">Löschen</div>
				</div>
			</div>
		</div>
	</div>
</div>
