<ngx-spinner size="medium" type="timer">
	<p style="font-size: 20px; color: white">Bitte warten...</p>
</ngx-spinner>


<div page page-user-detail *ngIf="(user$ | async)">
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Benutzer
				</div>
				<div class="card-body">
					<div section>
						<strong>Benutzername:</strong><br>
						{{(user$ | async).Username}}
					</div>
					<div section>
						<strong>E-Mail:</strong><br>
						<div *ngIf="(user$ | async).Email">{{(user$ | async).Email}}</div>
						<div *ngIf="!(user$ | async).Email">- keine -</div>
					</div>
					<div section>
						<strong>Rollen:</strong><br>
						<span *ngIf="!(user$ | async).Roles || (user$ | async).Roles.length === 0">- keine -</span>
						<span *ngIf="(user$ | async).Roles && (user$ | async).Roles.length > 0">
							<span *ngFor="let role of (user$ | async).Roles">
								{{role}}
							</span>
						</span>
					</div>
					<div section>
						<strong>Zuletzt online:</strong><br>
						<span *ngIf="(user$ | async).LastLogin !== (user$ | async).LastAction">{{formatDate((user$ |
							async).LastAction)}}</span>
						<span *ngIf="(user$ | async).LastLogin === (user$ | async).LastAction">noch nie</span>
					</div>
					<div section>
						<strong>Registrierungsdatum:</strong><br>

						{{formatDate((user$ | async).RegisterDate)}}
					</div>
					<div section>
						<strong>Datenbank ID:</strong><br>
						{{userID}}
					</div>
					<div section danger *ngIf="(user$ | async).Inactive">
						<strong>Hinweis:</strong><br>

						<span>Dieser Benutzer wurde gesperrt.</span>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Profil
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-sm-3 col-xs-12">
							<img *ngIf="!(profile$ | async) || !(profile$ | async).AvatarFile" avatar src="assets/default_avatar.svg">
							<img *ngIf="(profile$ | async) && (profile$ | async).AvatarFile" avatar
								[src]="getAvatarURL(profile$ | async)">
						</div>
						<div class="col-sm-7 col-xs-12">
							<div section>
								<strong>Name:</strong><br>
								{{(profile$ | async).Lastname}}, {{(profile$ | async).Firstname}}
							</div>
							<div section>
								<strong>Update notwendig:</strong><br>
								{{(profile$ | async).NeedsUpdate}}
							</div>
							<div section>
								<strong>Status:</strong><br>
								{{(profile$ | async).AboutMe}}
							</div>
							<div section>
								<strong>Telefon:</strong><br>
								<div contactdetail *ngFor="let phonenumber of (profile$ | async).PhoneNumbers">
									{{phonenumber.label}}:<br>
									<a [href]="'tel:' + phonenumber.value">{{phonenumber.value}}</a><br>
								</div>

							</div>
							<div section>
								<strong>E-Mail:</strong><br>
								<div contactdetail *ngFor="let email of (profile$ | async).EmailAddresses">
									{{email.label}}:<br>
									<a [href]="'mailto:' + email.value">{{email.value}}</a><br>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Chat-Gruppen
				</div>
				<div class="card-body">
					<div class="btn btn-primary" (tap)="manageChatGroups();">Bearbeiten</div>
					<br><br>
					<ul class="list-group">
						<li class="list-group-item" *ngFor="let conversation of (conversations$ | async)">{{conversation.Title}}
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Administration
				</div>
				<div class="card-body">
					<div class="btn btn-primary btn-block" (tap)="changePassword();">Neues Passwort setzen</div>
					<div class="btn btn-primary btn-block" (tap)="changePasswordRandom();">Neues zufälliges Passwort setzen</div>
					<div class="btn btn-warning btn-block" (tap)="inactivateAccount();" *ngIf="!(user$ | async).Inactive">Benutzer
						sperren</div>
					<div class="btn btn-warning btn-block" (tap)="activateAccount();" *ngIf="(user$ | async).Inactive">Benutzer
						entsperren</div>
					<div class="btn btn-danger btn-block" (tap)="deleteUser();">Benutzer löschen</div>
					<hr>
					<div class="btn btn-secondary btn-block" *ngIf="!isAdmin()" (tap)="promoteToAdmin();">Zum Administrator
						ernennen</div>
					<div class="btn btn-secondary btn-block" *ngIf="isAdmin()" (tap)="demoteFromAdmin();">Administratorrechte
						entziehen</div>
				</div>
			</div>
		</div>
	</div>
</div>