<ngx-spinner size="medium" type="timer">
  <p style="font-size: 20px; color: white">Bitte warten...</p>
</ngx-spinner>

<div page page-system>
  <div class="row">
    <div class="col-md-12 col-lg-6 offset-lg-3 ">
      <div class="card">
        <div class="card-header">
          System-Informationen
        </div>
        <div class="card-body">

          <div class="alert alert-danger" role="alert" *ngIf="(diskSpaceLow$() | async)">
            <strong>ACHTUNG!</strong> Der Speicherplatz auf Ihrem Server wird knapp! Sie müssen dieses Problem umgehend
            beheben, da sonst die Datenintegrität gefährdet ist.
          </div>

          <table class="diskspace">
            <tr>
              <td>Speicherplatz gesamt:</td>
              <td>{{convertBytesToReadable((diskSpace$ | async).size.bytes)}}</td>
            </tr>
            <tr>
              <td>Noch frei:</td>
              <td> {{convertBytesToReadable((diskSpace$ | async).free.bytes)}}</td>
            </tr>
            <tr>
              <td>Verbrauchter Speicherplatz:</td>
              <td>{{round((diskSpace$ | async).used.percent)}}%</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6 offset-lg-3 ">
      <div class="card">
        <div class="card-header">
          Wartung
        </div>
        <div class="card-body">
          <div class="btn btn-danger btn-block" (tap)="resetEventSystem();">EventSystem zurücksetzen</div>
        </div>
      </div>
    </div>
  </div>
</div>