import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { IErrorEvent } from 'src/app/models/ErrorEvent.model';
import { ConfigService } from 'src/app/service/config.service';
import { ModalService } from 'src/app/service/modal.service';
import { UserService } from 'src/app/service/user.service';
import { randomString } from 'src/util/randomString';

@Component({
	selector: 'app-page-create-admin',
	templateUrl: './page-create-admin.component.html',
	styleUrls: ['./page-create-admin.component.scss']
})
export class PageCreateAdminComponent implements OnInit {
	icons = {
		plusCircle: faPlusCircle
	};

	licenceKey = '';
	name = '';
	firstname = '';
	lastname = '';
	username = '';
	email = '';

	@ViewChild('createUserForm') form: NgForm;

	constructor(
		protected modalService: ModalService,
		protected userService: UserService,
		protected spinner: NgxSpinnerService,
		protected router: Router,
		public config: ConfigService
	) { }

	ngOnInit() {
		alert('In Ihrem System wurde noch kein Administrator angelegt. Bitte legen Sie nun einen an.');
	}

	reset() {
		this.licenceKey = '';
		this.name = '';
		this.firstname = '';
		this.lastname = '';
		this.email = '';

		this.form.reset();
	}

	submit() {
		this.spinner.show();
		const pwd = this.generatePassword();

		this.userService.createInitAdmin(this.username, pwd, this.firstname, this.lastname, this.email, this.licenceKey)
			.subscribe((data: any) => {
				this.spinner.hide();

				this.modalService
					.alert(
						'Erfolg',
						`
							Der Administrator wurde erfolgreich erstellt.<br><br>
							Name: ${this.lastname}, ${this.firstname}<br>
							Benutzername: ${this.username}<br>
							Passwort: ${pwd}
						`
					).subscribe(() => {
						this.complete();
					});
			}, (err: IErrorEvent) => {
				this.spinner.hide();
				if (err.status === 409) {
					alert(`Fehler: Benutzername oder E-Mail wird bereits genutzt. Code: 409 Conflict`);
				} else if (err.status === 423) {
					alert(`Fehler: Das maximale Benutzerlimit wurde erreicht.
Bitte erweitern Sie Ihre Lizenz um weitere Benutzer erstellen zu können.
Code: 423 Locked`);
				} else if (err.status === 401) {
					alert(`Fehler: Der Lizenzschlüssel stimmt nicht mit der installierten Lizenz überein. Code: 401 Unauthorized`);
				} else {
					alert(`Fehler: Der Benutzer konnte nicht angelegt werden. Code: ${err.status} ${err.message}`);
				}
				console.log('ERR', err);
			});
	}

	abort() {
		this.router.navigate(['/']);
	}

	protected generatePassword(length: number = 8): string {
		return randomString(length);
	}

	protected complete() {
		this.reset();
		this.router.navigate(['/']);
	}

}
