import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faEnvelope, faLink, faUnlock, faUser } from '@fortawesome/pro-regular-svg-icons';
import { filter, take } from 'rxjs/operators';
import { ConfigService } from 'src/app/service/config.service';
import { StoreService } from 'src/app/service/store.service';
import { IErrorEvent } from '../../models/ErrorEvent.model';
import { ISocketEvent } from '../../models/SocketEvent.model';
import { IUser } from '../../models/User.model';
import { AuthenticationService } from '../../service/authentication.service';

@Component({
	selector: 'app-page-login',
	templateUrl: './page-login.component.html',
	styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
	icons = {
		envelope: faEnvelope,
		unlock: faUnlock,
		user: faUser,
		key: faLink
	};

	serverCode: string;
	username: string;
	password: string;

	serverCodeStore$ = this.store.getSubject<string>('login:serverCode', '', true);
	usernameStore$ = this.store.getSubject<string>('login:username', '', true);

	constructor(
		protected router: Router,
		protected auth: AuthenticationService,
		protected store: StoreService,
		public config: ConfigService
	) { }

	ngOnInit() {
		if (this.auth.authState$.getValue() === true) {
			this.router.navigate(['main']);
		}

		this.restoreLoginData();
	}

	async applyServerCode() {
		return this.validateServerCode();
	}

	async validateServerCode() {
		try {
			return await this.config.retrieveConfig(this.serverCode, true, false);
		} catch (err) {
			if (err.status === 404) {
				alert('Der Verbindungscode ist ungültig. Bitte prüfen Sie auch die Groß- und Kleinschreibung.');
				return;
			}

			alert(`Ein Fehler ist aufgetreten. Bitte prüfen Sie Ihre Internetverbindung. Code: ${err.status}`);
		}

	}

	async login() {
		const result = await this.validateServerCode();

		if (!result) {
			return;
		}

		this.auth.login({
			Username: this.username,
			Password: this.password,
			Platform: 'admin-ui'
		}).subscribe((resultEvent: ISocketEvent) => {
			const user: IUser = resultEvent.payload;

			if (user && user.Roles && user.Roles.includes('Admin')) {
				this.router.navigate(['main']);

				this.persistLoginData(this.serverCode, this.username);
			} else {
				alert('Fehler: Sie haben keine Administrationsrechte.');
				this.auth.logout();
			}
		}, (err: IErrorEvent) => {
			alert('Fehler: Bitte prüfen Sie ihre Zugangsdaten');
		});
	}

	protected restoreLoginData(): void {
		if (this.config.predefinedCode === '') {
			this.serverCodeStore$
				.pipe(
					// Skip uninitialized value
					filter((code: string): boolean => {
						return code !== undefined;
					}),
					take(1)
				).subscribe((code: string) => {
					this.serverCode = code;
				});
		} else {
			this.serverCode = this.config.predefinedCode;
		}

		this.usernameStore$
			.pipe(
				// Skip uninitialized value
				filter((username: string): boolean => {
					return username !== undefined;
				}),
				take(1)
			).subscribe((username: string) => {
				this.username = username;
			});
	}

	protected persistLoginData(serverCode: string, username: string): void {
		this.serverCodeStore$.next(serverCode);
		this.usernameStore$.next(username);
	}

}
