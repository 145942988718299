<div page page-user-list>
	<div class="card">
		<div class="card-header">
			<fa-icon [icon]="icons.users"></fa-icon> Übersicht über alle Benutzer
		</div>
		<div class="card-body">
			<div class="content">
				<div class="btn btn-warning" (tap)="refresh()"><fa-icon [icon]="icons.refresh"></fa-icon>&nbsp;&nbsp; Aktualisieren</div><br><br>
			</div>
			<div id="DataTables_Table_0_wrapper" class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer" *ngIf="(users$ | async)">
				<div class="row">
					<div class="col-12">
						<form class="form-inline">
							<label class="sr-only">Suche</label>
							<div class="input-group mb-2 mr-sm-2">
								<div class="input-group-prepend">
								<div class="input-group-text"><fa-icon [icon]="icons.search"></fa-icon></div>
								</div>
								<input type="text" class="form-control" name="searchTerm" placeholder="Suche" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}">
							</div>
						</form>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-striped table-bordered table-hover  datatable dataTable no-footer" role="grid" aria-describedby="DataTables_Table_0_info" style="border-collapse: collapse !important">
							<thead>
								<tr role="row">
									<th tabindex="0" rowspan="1" colspan="1">Benutzername</th>
									<th tabindex="0" rowspan="1" colspan="1">Name</th>
									<th tabindex="0" rowspan="1" colspan="1">Zuletzt online</th>
									<th tabindex="0" rowspan="1" colspan="1">Rollen</th>
									<!--th tabindex="0" rowspan="1" colspan="1">Aktionen</th-->
								</tr>
							</thead>
							<tbody>
								<tr role="row" class="odd clickable" *ngFor="let user of filter(searchTerm, (users$ | async))" (tap)="navigateTo('user-detail/' + user._id);">
									<td class="sorting_1">{{user.Username}}</td>
									<td class="sorting_1">{{getName(user)}}</td>
									<td>
										<span *ngIf="user.LastLogin !== user.LastAction">{{formatDate(user.LastAction)}}</span>
										<span *ngIf="user.LastLogin === user.LastAction">noch nie</span>
									</td>
									<td>
										<span *ngIf="user.Roles.length > 0">
											<span *ngFor="let role of user.Roles" class="badge badge-success">
												{{role}}
											</span>
										</span>
									</td>
									<!---- td>
										<div class="btn btn-success" (tap)="navigateTo('user-detail/' + user._id)">
											<fa-icon [icon]="icons.view"></fa-icon>
										</div>
										<div class="btn btn-info">
											<fa-icon [icon]="icons.edit"></fa-icon>
										</div>
										<div class="btn btn-danger">
											<fa-icon [icon]="icons.trash"></fa-icon>
										</div>
									</td-->
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
