import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { flatMap, map, take } from 'rxjs/operators';
import { AppStateService } from 'src/app/service/app-state.service';
import { convertObservableToBehaviorSubject } from '../../../util/convertObservableToBehaviorSubject';
import { getTransformationFileURL } from '../../../util/fileTransformationHelper';
import { randomString } from '../../../util/randomString';
import { IPickerOption } from '../../modals/modal-picker/picker-option.interface';
import { IChatConversation } from '../../models/ChatConversation.model';
import { IErrorEvent } from '../../models/ErrorEvent.model';
import { IProfile } from '../../models/Profile.model';
import { IUser, IUserDeleteRequestPayload, UserDeleteRequestPayload } from '../../models/User.model';
import { ChatService } from '../../service/chat.service';
import { ModalService } from '../../service/modal.service';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';


@Component({
	selector: 'app-page-user-detail',
	templateUrl: './page-user-detail.component.html',
	styleUrls: ['./page-user-detail.component.scss']
})
export class PageUserDetailComponent implements OnInit {
	userID: string;
	user$: BehaviorSubject<IUser>;
	profile$: BehaviorSubject<IProfile>;
	conversations$: BehaviorSubject<IChatConversation[]>;

	constructor(
		protected route: ActivatedRoute,
		protected modalService: ModalService,
		protected spinner: NgxSpinnerService,
		protected userService: UserService,
		protected profileService: ProfileService,
		protected chatService: ChatService,
		protected cdr: ChangeDetectorRef,
		protected router: Router,
		protected appState: AppStateService
	) { }

	ngOnInit() {
		this.route.params
			.pipe(take(1))
			.subscribe((params: Params) => {
				this.setupSubjects(params);
			});
	}

	setupSubjects(params: Params) {
		if (params.UserID) {
			this.userID = params.UserID;
			this.getUser(this.userID);
			this.getProfile(this.userID);
			this.getConversations(this.userID);
		}
	}

	refresh() {
		this.userService.refreshUsers();
	}

	getUser(UserID: string): void {
		if (this.user$) {
			console.error('user$ already exists');
			return;
		}

		const observable = this.userService
			.getUser(UserID);

		this.user$ = convertObservableToBehaviorSubject(observable);
	}

	getProfile(UserID: string): void {
		if (this.profile$) {
			console.error('profile$ already exists');
			return;
		}

		const observable = this.profileService
			.getProfile(UserID);

		this.profile$ = convertObservableToBehaviorSubject(observable);
	}

	getConversations(UserID: string): void {
		if (this.conversations$) {
			console.error('conversations$ already exists');
			return;
		}

		const observable = this.chatService
			.getConversationsForUser(UserID)
			.pipe(
				map((conversations: IChatConversation[]) => {
					if (!conversations) {
						alert(`Fehler: Die Chats des Users konnten nicht abgerufen werden.`);
						return;
					}

					return conversations.filter((conversation: IChatConversation): boolean => {
						return conversation.Type === 'group';
					});
				})
			);

		this.conversations$ = convertObservableToBehaviorSubject(observable);
	}

	manageChatGroups(): void {
		const conversations: IChatConversation[] = this.chatService.groupConversations$.getValue();

		// Generate Options for the Picker
		const options: IPickerOption[] = conversations.map((conversation): IPickerOption => {
			return {
				label: conversation.Title,
				enabled: conversation.Participants.includes(this.userID),
				value: conversation._id
			};
		});

		const title = `Chat-Gruppen auswählen`;
		const description = `Markieren Sie die Chatgruppen, in denen der User teilnehmen soll.`;

		this.modalService
			.picker(options, title, description)
			.subscribe((result: IPickerOption[]) => {
				const chatGroupIDs = result.filter((option: IPickerOption) => {
					return option.enabled;
				}).map((option: IPickerOption): string => {
					return option.value;
				});

				this.chatService.updateUserParticipations(this.userID, chatGroupIDs).subscribe();
			}, (err: any) => {
				console.log('closed with error', err);
			});
	}

	passwordValidator(str: string): boolean {
		if (!str || str.length < 6) {
			return false;
		}
		return true;
	}

	changePassword(): void {
		this.modalService
			.prompt('Neues Passwort setzen',
				`
				Bitte geben sie ein neues Passwort für den User ein.
				Es muss mindestens 6 Zeichen lang sein und es wird nicht per Mail versendet.
			`,
				'Neues Passwort', null, this.passwordValidator)
			.subscribe((newPassword: string) => {
				this.spinner.show();

				this.userService
					.updatePassword(this.userID, newPassword)
					.subscribe(() => {
						this.spinner.hide();
						this.pwdSucessDialog(newPassword);
					}, (err: IErrorEvent) => {
						this.spinner.hide();
						alert(`Fehler: Das Passwort konnte nicht gesetzt werden. Code: ${err.status} ${err.message}`);
					});

			});
	}

	inactivateAccount() {
		this.modalService
			.confirmation(
				`Benutzer sperren`,
				`
					Wenn Sie einen Benutzer sperren, kann er sich nicht mehr einloggen.<br>
					Auch aktive Sessions werden gesperrt.<br>
					Es werden keine Daten gelöscht.<br>
					<br>
					Möchten Sie fortfahren?
				`
			).subscribe((decision: boolean) => {
				if (!decision) {
					return;
				}

				this.spinner.show();

				this.userService
					.inactivate(this.userID)
					.subscribe((user: IUser) => {
						this.spinner.hide();
					}, (err) => {
						this.spinner.hide();

						this.modalService.alert(
							'Fehler',
							`Der Benutzer konnte nicht gesperrt werden.<br>
							<br>
							(Fehlercode: ${err.status} - ${err.name})`
						);
					});
			});
	}

	activateAccount() {
		this.modalService
			.confirmation(
				`Benutzer entsperren`,
				`
					Wenn Sie einen Benutzer entsperren, kann er sich wieder einloggen.<br>
					<br>
					Möchten Sie fortfahren?
				`
			).subscribe((decision: boolean) => {
				if (!decision) {
					return;
				}

				this.spinner.show();

				this.userService
					.activate(this.userID)
					.subscribe((user: IUser) => {
						this.spinner.hide();
					}, (err) => {
						this.spinner.hide();

						this.modalService.alert(
							'Fehler',
							`Der Benutzer konnte nicht entsperrt werden.<br>
							<br>
							(Fehlercode: ${err.status} - ${err.name})`
						);
					});
			});
	}

	changePasswordRandom(): void {
		const newPassword = randomString(8);

		this.userService
			.updatePassword(this.userID, newPassword)
			.subscribe(() => {
				this.spinner.hide();
				this.pwdSucessDialog(newPassword);
			}, (err: IErrorEvent) => {
				this.spinner.hide();
				alert(`Fehler: Das Passwort konnte nicht gesetzt werden. Code: ${err.status} ${err.message}`);
			});
	}

	pwdSucessDialog(newPassword: string) {
		this.modalService
			.confirmation(
				'Erfolg',
				`
					Der Benutzer wurde erfolgreich aktualisiert.<br><br>
					Name: ${this.profile$.getValue().Lastname}, ${this.profile$.getValue().Firstname}<br>
					Benutzername: ${this.user$.getValue().Username}<br>
					Passwort: ${newPassword}<br>
					<br>
					Möchten Sie die Daten drucken?
				`
			).subscribe((showPrint: boolean) => {
				this.userService.recentlyCreatedUser = {
					name: this.profile$.getValue().Name,
					firstname: this.profile$.getValue().Firstname,
					lastname: this.profile$.getValue().Lastname,
					username: this.user$.getValue().Username,
					password: newPassword,
					email: this.user$.getValue().Email
				};

				if (showPrint) {
					this.router.navigate(['user-print']);
				}
			});
	}

	deleteUser() {
		const UserID = this.userID;

		this._confirmUserDeletion()
			.pipe(
				map((decision: boolean): IUserDeleteRequestPayload => {
					if (decision === true) {
						return new UserDeleteRequestPayload(UserID);
					} else {
						return null;
					}
				}),
				flatMap((payload: IUserDeleteRequestPayload) => {
					if (!payload) {
						return of(payload);
					}

					return this._confirmKeepMessages()
						.pipe(
							map((decision) => {
								payload.KeepMessages = decision;

								return payload;
							})
						);
				}),
				flatMap((payload: IUserDeleteRequestPayload) => {
					if (!payload || !payload.KeepMessages) {
						return of(payload);
					}

					return this._askForAlternateSenderName()
						.pipe(
							map((AlternateSenderName) => {
								payload.AlternateSenderName = AlternateSenderName;

								return payload;
							})
						);
				})
			).subscribe((payload: IUserDeleteRequestPayload) => {
				if (!payload) {
					return;
				}

				// tslint:disable-next-line: no-shadowed-variable
				const { UserID, KeepMessages, AlternateSenderName } = payload;

				this.spinner.show();

				this.userService
					.delete(UserID, KeepMessages, AlternateSenderName)
					.subscribe((response) => {
						this.spinner.hide();
						this.modalService.alert(
							'Erfolg',
							'Der Benutzer wurde erfolgreich gelöscht.'
						);

						this.userService.refreshUsers();

						this.goBack();
					}, (err) => {
						this.spinner.hide();
						this.modalService.alert(
							'Fehler',
							`Es ist ein Fehler aufgetreten. Der Benutzer konnte nicht gelöscht werden. Code: ${err.status} - ${err.message}`
						);
					});
			});
	}

	_confirmUserDeletion() {
		return this.modalService
			.confirmation(
				'Benutzer löschen',
				`Sind Sie sicher, dass Sie den Benutzer "${this.profile$.getValue().Lastname}, ${this.profile$.getValue().Firstname}" löschen möchten?`
			);
	}

	_confirmKeepMessages() {
		return this.modalService
			.confirmation(
				'Benutzer löschen',
				`Möchten Sie, dass die Nachrichten des Benutzers "${this.profile$.getValue().Lastname}, ${this.profile$.getValue().Firstname}" gelöscht werden?`
			).pipe(
				map((decision) => {
					return !decision;
				})
			);
	}

	_askForAlternateSenderName() {
		return this.modalService
			.prompt(
				'Benutzer löschen',
				'Bitte wählen Sie den Absendernamen, der von nun an im Chatverlauf angezeigt werden soll.',
				'Absendername',
				`${this.profile$.getValue().Lastname}, ${this.profile$.getValue().Firstname}`,
				(str: string) => str.length > 0
			);
	}

	getAvatarURL(profile: IProfile): string {
		return getTransformationFileURL(profile.AvatarFile, 'mini');
	}

	formatDate(dateString: string) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	goBack() {
		this.router.navigate(['main/user-list']);
	}

	isAdmin(): boolean {
		const user = this.user$.getValue();

		return user.Roles.includes('Admin');
	}

	promoteToAdmin(): void {
		this.userService.promote(this.userID)
			.subscribe(() => {
				this.appState.refreshState();
			}, (err: any) => {
				console.error(err);
			});
	}

	demoteFromAdmin(): void {
		this.userService.demote(this.userID)
			.subscribe(() => {
				this.appState.refreshState();
			}, (err: any) => {
				console.error(err);
			});
	}

}
