<div chat-bubble>
	<div bubble [class]="direction">
		<div formatted-name>
			{{formattedSenderName}}
		</div>

		<div img *ngIf="message.Type === 'image'" (tap)="openFile(message.File)" [style.width]="getImageDimension('thumb', 'width')" [style.height]="getImageDimension('thumb', 'height')">
			<img [src]="getImageURL('thumb')">
		</div>

		<div file *ngIf="message.Type === 'file'" (tap)="openFile(message.File, message.Text)">
			<img src="assets/default_attachment_download.svg" >

			<div filename> {{message.Text}}</div>
		</div>

		<div *ngIf="message.Type !== 'file'" text [innerHtml]="(message.Text | linkify)"></div>
		
		<div date (tap)="showSeenBy()">
			{{formatDate(message.Created)}} <fa-icon [icon]="statusIcon" [ngClass]="{seen: status === 'SEEN'}"></fa-icon>  <!--ion-icon  *ngIf="direction === 'sent'" ></ion-icon-->
		</div>
	</div>
</div>