import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IPickerOption } from './picker-option.interface';
import { faSquare, faCheckSquare } from '@fortawesome/pro-light-svg-icons';
import { faSearch, faBackspace } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-modal-picker',
	templateUrl: './modal-picker.component.html',
	styleUrls: ['./modal-picker.component.scss']
})
export class ModalPickerComponent implements OnInit {

	public title: string;
	public description: string;
	public options: IPickerOption[] = [];

	searchTerm: string = '';

	icons = {
		square: faSquare,
		checkSquare: faCheckSquare,
		search: faSearch,
		clear: faBackspace
	};

	constructor(
		protected activeModal: NgbActiveModal
	) {}

	ngOnInit() {
	}

	abort() {
		this.activeModal.dismiss();
	}

	close(result: any) {
		this.activeModal.close(result);
	}

	toggle(option: IPickerOption) {
		option.enabled = !option.enabled;
	}

	filter( searchTerm: string, options: IPickerOption[]): IPickerOption[] {
		if (!searchTerm || searchTerm.length === 0) {
			return options;
		}

		return options.filter((option: IPickerOption): boolean => {
			const terms: string[] = searchTerm.toLowerCase().split(/[ ,]+/);
			const val: string =  option.label.toLocaleLowerCase();

			let result: boolean = false;

			terms.forEach((term: string) => {
				if (val.indexOf(term) !== -1) {
					result = true;
				}
			});

			return result;
		});
	}
}
