<div class="flex-row align-items-center full-viewport-height">
	<div class="row justify-content-center">
		<div class="col-md-6">
			<div class="clearfix">
				<h1 class="float-left display-3 mr-4">404</h1>
				<h4 class="pt-3">Oops! Das hätte nicht passieren dürfen.</h4>
				<p class="text-muted">Die Seite konnte nicht gefunden werden.</p>
			</div>
		</div>
	</div>
</div>