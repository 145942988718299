import { Injectable } from '@angular/core';

import { LocalStorageService } from 'ngx-webstorage';

@Injectable({
	providedIn: 'root'
})
export class StorageService {

	constructor(
		protected storageProvider: LocalStorageService
	) { }

	public set(key: string, value: any): void {
		this.storageProvider.store(key, value);
	}

	public get(key: string): any {
		return this.storageProvider.retrieve(key);
	}

	public remove(key: string): void {
		this.storageProvider.clear(key);
	}
}
