import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons';
import * as moment from 'moment';
import { BehaviorSubject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IPickerOption } from 'src/app/modals/modal-picker/picker-option.interface';
import { IChatBroadcastList } from 'src/app/models/ChatBroadcastList.model';
import { IProfile } from 'src/app/models/Profile.model';
import { IUser } from 'src/app/models/User.model';
import { BroadcastListService } from 'src/app/service/broadcast-list.service';
import { ModalService } from 'src/app/service/modal.service';
import { ProfileService } from 'src/app/service/profile.service';
import { UserService } from 'src/app/service/user.service';
import { convertObservableToBehaviorSubject } from 'src/util/convertObservableToBehaviorSubject';
import { getTransformationFileURL } from 'src/util/fileTransformationHelper';

@Component({
	selector: 'app-page-broadcast-detail',
	templateUrl: './page-broadcast-detail.component.html',
	styleUrls: ['./page-broadcast-detail.component.scss']
})
export class PageBroadcastDetailComponent implements OnInit, OnDestroy {

	bcListID: string;
	bcList$: BehaviorSubject<IChatBroadcastList>;
	receiverProfiles$: BehaviorSubject<IProfile[]>;

	icons = {
		bcList: faUsersClass,
		refresh: faSync,
		search: faSearch,
	};

	private bcListSubscription?: Subscription;

	private subscriptions: Subscription[] = [];

	constructor(
		protected route: ActivatedRoute,
		protected bcListService: BroadcastListService,
		protected profileService: ProfileService,
		protected modalService: ModalService,
		protected userService: UserService,
		protected router: Router
	) { }

	ngOnInit() {
		const subscription = this.route.params
			.pipe(take(1))
			.subscribe((params: Params) => {
				if (params.ListID) {
					this.bcListID = params.ListID;
					// this.gatherData(this.conversationID);
					const observableList = this.bcListService.observeByID(this.bcListID);
					if (observableList) {
						this.bcList$ = convertObservableToBehaviorSubject(observableList);
						this.observeBcList();
					}
				}
			});

		this.subscriptions.push(subscription);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());

		this.bcListSubscription?.unsubscribe();
	}

	private observeBcList() {
		if (this.bcListSubscription) {
			this.bcListSubscription.unsubscribe();
			this.bcListSubscription = undefined;
		}

		this.bcListSubscription = this.bcList$
			.subscribe((bcList: IChatBroadcastList) => {
				if (!bcList) {
					return;
				}
				this.getProfiles(bcList.ReceiverIDs);
			});
	}

	editTitle() {
		// Prompt
		this.modalService
			.prompt(
				'Titel ändern',
				`Geben Sie bitte einen neuen Titel für die Verteilerliste "${this.bcList$.getValue().Title}" ein.`,
				this.bcList$.getValue().Title,
				null,
				(val: string) => {
					if (!val) {
						return false;
					}

					return val.length > 0;
				}
			).subscribe((newTitle: string) => {
				this.bcListService
					.updateTitle(this.bcListID, newTitle)
					.subscribe((updatedList) => {
						// Success
						console.log('UPDATE', updatedList);
					});
			});
	}

	editReceivers() {
		// Modal
		const allUsers: IUser[] = this.userService.users$.getValue();

		// Generate options for the picker
		const options: IPickerOption[] = allUsers.map((user: IUser): IPickerOption => ({
			label: user.Username,
			enabled: this.isUserReceiver(user._id),
			value: user._id
		}));

		const title = `Empfänger auswählen`;
		const description = `Markieren Sie die Benutzer, die Empfänger in dieser Verteilerliste sein sollen.`;

		this.modalService
			.picker(options, title, description)
			.subscribe((result: IPickerOption[]) => {
				const userIDs: string[] = result.filter((option: IPickerOption) => {
					return option.enabled;
				}).map((option: IPickerOption) => {
					return option.value;
				});

				this.bcListService.updateReceivers(this.bcListID, userIDs).subscribe();
			}, (err: any) => {
				console.log('closed with error', err);
			});
	}

	remove() {
		this.modalService
			.confirmation(
				'Hinweis',
				`
				Möchten sie die Verteilerliste "${this.bcList$.getValue().Title}" löschen?
			`
			).subscribe((decision: boolean) => {
				if (decision) {
					this.bcListService.remove(this.bcListID).subscribe(() => {
						// Success
						this.leave();
					});
				}
			});
	}

	leave() {
		this.navigateTo('chat-broadcast-list');
	}

	navigateTo(target: string) {
		this.router.navigate(['main/' + target]);
	}

	isUserReceiver(UserID: string) {
		return this.bcList$.getValue().ReceiverIDs.includes(UserID);
	}

	formatDate(dateString: string | Date) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	getProfiles(ReceiverIDs: string[]): void {
		const observable = this.profileService
			.getProfiles(ReceiverIDs);

		this.receiverProfiles$ = convertObservableToBehaviorSubject(observable);
	}

	getAvatarURL(profile: IProfile): string {
		const palceholderURL = 'assets/default_avatar.svg';

		if (!profile || !profile.AvatarFile) {
			return palceholderURL;
		}

		const url = getTransformationFileURL(profile.AvatarFile, 'mini');

		return url ? url : palceholderURL;
	}

}
