import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { randomString } from '../../../util/randomString';
import { IPickerOption } from '../../modals/modal-picker/picker-option.interface';
import { IChatConversation } from '../../models/ChatConversation.model';
import { IErrorEvent } from '../../models/ErrorEvent.model';
import { ChatService } from '../../service/chat.service';
import { ModalService } from '../../service/modal.service';
import { UserService } from '../../service/user.service';

@Component({
	selector: 'app-page-user-create',
	templateUrl: './page-user-create.component.html',
	styleUrls: ['./page-user-create.component.scss']
})
export class PageUserCreateComponent implements OnInit {
	icons = {
		plusCircle: faPlusCircle
	};

	name = '';
	firstname = '';
	lastname = '';
	username = '';
	email = '';

	chatGroups: IPickerOption[] = [];

	@ViewChild('createUserForm') form: NgForm;

	constructor(
		protected modalService: ModalService,
		protected userService: UserService,
		protected chatService: ChatService,
		protected spinner: NgxSpinnerService,
		protected router: Router
	) { }

	ngOnInit() {
		this.setupGroups();
	}

	reset() {
		this.setupGroups();
		this.name = '';
		this.firstname = '';
		this.lastname = '';
		this.email = '';

		this.form.reset();
	}

	submit() {
		this.spinner.show();
		const pwd = this.generatePassword();

		this.userService.create(this.username, pwd, this.firstname, this.lastname, this.email, this.getGroupIDs())
			.subscribe((data: any) => {
				this.userService.recentlyCreatedUser = {
					username: this.username,
					password: pwd,
					name: this.name,
					firstname: this.firstname,
					lastname: this.lastname,
					email: this.email
				};

				this.spinner.hide();

				this.modalService
					.confirmation(
						'Erfolg',
						`
							Der Benutzer wurde erfolgreich erstellt.<br><br>
							Name: ${this.lastname}, ${this.firstname}<br>
							Benutzername: ${this.username}<br>
							Passwort: ${pwd}<br>
							<br>
							Möchten Sie die Daten drucken?
						`
					).subscribe((showPrint: boolean) => {
						if (showPrint) {
							this.router.navigate(['user-print']);
						}

						this.reset();
					});
			}, (err: IErrorEvent) => {
				this.spinner.hide();
				if (err.status === 409) {
					alert(`Fehler: Benutzername oder E-Mail wird bereits genutzt. Code: 409 Conflict`);
				} else if (err.status === 423) {
					alert(`Fehler: Das maximale Benutzerlimit wurde erreicht.
Bitte erweitern Sie Ihre Lizenz um weitere Benutzer erstellen zu können.
Code: 423 Locked`);
				} else {
					alert(`Fehler: Der Benutzer konnte nicht angelegt werden. Code: ${err.status} ${err.message}`);
				}
				console.log('ERR', err);
			});
	}

	getGroupIDs(): string[] {
		return this.getEnabledOptions()
			.filter((option: IPickerOption): boolean => {
				return option.enabled;
			})
			.map((option: IPickerOption): string => {
				return option.value;
			});

	}

	manageChatGroups(): void {
		const title: string = `Chat-Gruppen auswählen`;
		const description: string = `Bitte wählen Sie die Chat-Gruppen aus, in denen der Benutzer teilnehmen soll.`;

		this.modalService
			.picker(this.chatGroups, title, description)
			.subscribe((result: IPickerOption[]) => {
				this.chatGroups = result;
			});
	}

	setupGroups(): void {
		const conversations: IChatConversation[] = this.chatService.groupConversations$.getValue();

		const pickerOptions: IPickerOption[] = conversations.map((conversation: IChatConversation): IPickerOption => {
			const pickerOption: IPickerOption = {
				enabled: false,
				label: conversation.Title,
				value: conversation._id
			};

			return pickerOption;
		});

		this.chatGroups = pickerOptions;
	}

	getEnabledOptions(): IPickerOption[] {
		return this.chatGroups.filter((option: IPickerOption) => {
			return option.enabled;
		});
	}

	protected generatePassword(length: number = 8): string {
		return randomString(length);
	}


}
