import { Component, OnInit } from '@angular/core';
import { UserService } from '../../service/user.service';
import { ConfigService } from '../../service/config.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-page-user-print',
	templateUrl: './page-user-print.component.html',
	styleUrls: ['./page-user-print.component.scss']
})
export class PageUserPrintComponent implements OnInit {

	name: string;
	firstname: string;
	lastname: string;
	username: string;
	email: string;
	password: string;

	apiConfig$ = this.config.apiConfig$;
	mainConfig = this.config.mainConfig;
	printInfo = this.config.getPrintInfo();

	constructor(
		protected userService: UserService,
		protected config: ConfigService,
		protected router: Router
	) { }

	ngOnInit() {
		const recentlyCreatedUser = this.userService.recentlyCreatedUser;

		this.name = recentlyCreatedUser.name;
		this.firstname = recentlyCreatedUser.firstname;
		this.lastname = recentlyCreatedUser.lastname;
		this.username = recentlyCreatedUser.username;
		this.email = recentlyCreatedUser.email;
		this.password = recentlyCreatedUser.password;

		setTimeout(() => {
			print();
		}, 1000);
	}

	goBack(): void {
		this.router.navigate(['main/user-create']);
	}

}
