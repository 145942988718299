import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-prompt',
	templateUrl: './modal-prompt.component.html',
	styleUrls: ['./modal-prompt.component.scss']
})
export class ModalPromptComponent implements OnInit {

	public buttonLabels = {
		okay: 'Okay',
		abort: 'Abbrechen'
	};

	public title: string;
	public description: string;
	public placeholder: string;
	public validator: (str: string) => boolean;

	public value: string;

	constructor(
		protected activeModal: NgbActiveModal
	) { }

	ngOnInit() {
	}

	abort() {
		this.activeModal.dismiss();
	}

	close(result: string) {
		this.activeModal.close(result);
	}

	validate(): boolean {
		if (!this.validator) {
			return true;
		}

		return this.validator(this.value);
	}

}
