<div page page-user-create>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-header">
					<fa-icon [icon]="icons.agreement"></fa-icon> Neue Vereinbarung erstellen
				</div>
				<div class="card-body">
					<div class="alert alert-primary" role="alert">
						<fa-icon [icon]="icons.info"></fa-icon> Info: Nachdem eine neue Vereinbarung erstellt wurde, muss diese von jedem einzelnen Benutzer akzeptiert werden.<br>Ein Benutzer kann die App nur dann weiter nutzen, wenn er alle Vereinbarungen akzeptiert hat.
					</div>
					<a class="btn btn-warning" href="https://docs.bico.app/docs/datenschutz-vorlage/" target="_blank">
						<fa-icon [icon]="icons.document"></fa-icon> Vorlage: Datenschutzerklärung
					</a>
					<hr>
					<form #createForm="ngForm" novalidate (ngSubmit)="submit();">
						<div class="form-group">
							<label>Titel</label>
							<input type="text" class="form-control" [(ngModel)]="newAgreement.Title" name="title" #titleField="ngModel" [ngClass]="{'is-invalid': titleField.invalid &&  titleField.touched}" placeholder="z.B. Datenschutzvereinbarung" minlength="2" required>
						</div>
						<div class="form-group">
							<label>Text</label>
							<!--textarea class="form-control" rows="3" [(ngModel)]="newAgreement.Html" name="html" #htmlField="ngModel" [ngClass]="{'is-invalid': htmlField.invalid &&  htmlField.touched}" placeholder="Text" minlength="2" required></!--textarea-->
							<angular-editor name="Html" [(ngModel)]="newAgreement.Html" [placeholder]="'Fügen Sie hier den Inhalt Ihre Vereinbarung ein'" [config]="editorConfig"></angular-editor>
						</div>
						<br>
						<br>
						<button type="submit" class="btn btn-primary" [disabled]="createForm.invalid">Erstellen</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>
