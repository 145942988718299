<div class="modal-header">
	<h5 class="modal-title">{{title}}</h5>
	<button type="button" class="close" aria-label="Close" (tap)="close();">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p [innerHTML]="description"></p>		
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (tap)="close();">{{buttonLabels.okay}}</button>
</div>
	