<ngx-spinner size="medium" type="timer">
	<p style="font-size: 20px; color: white">Bitte warten...</p>
</ngx-spinner>

<div page page-chat-detail *ngIf="conversation$ | async">
	<div class="row">
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Details
				</div>
				<div class="card-body">
					<div section conversation-avatar>
						<div avatar-wrapper>
							<app-avatar [changeable]="true" (result)="updateAvatarBase64($event);"
								[src]="getAvatarURL(conversation$ | async)"></app-avatar>
						</div>
					</div>
					<div section>
						<strong>Titel:</strong><br>
						{{(conversation$ | async).Title}}
					</div>
					<div section>
						<strong>Erstellungsdatum:</strong><br>
						{{formatDate((conversation$ | async).Created)}}
					</div>
					<div section>
						<strong>Datenbank ID:</strong><br>
						{{conversationID}}
					</div>
					<div section text-red *ngIf="(conversation$ | async).Locked">
						<strong>Diese Gruppe wurde gesperrt.</strong>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Teilnehmer
				</div>
				<div class="card-body">
					<div class="btn btn-primary" (tap)="editParticipants();">Bearbeiten</div>
					<br><br>
					<ul class="list-group" participant-list>
						<li class="list-group-item" *ngFor="let participant of (participantProfiles$ | async)">
							<img avatar [src]="getAvatarURL(participant)">{{participant.Lastname}}, {{participant.Firstname}}
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="row">
		<div class="col-lg-6">
			<div class="card" *ngIf="!hideChatHistory">
				<div class="card-header">
					Chat-Verlauf
				</div>
				<div class="card-body">
					<div class="btn btn-warning" (tap)="refreshMessages(conversationID)">
						<fa-icon [icon]="icons.refresh"></fa-icon>&nbsp;&nbsp; Aktualisieren
					</div><br><br>
					<div chat-history #scrollContainer>
						<app-chat-bubble *ngFor="let message of messages" [message]="message"
							[conversation]="conversation$ | async"></app-chat-bubble>
						<div *ngIf="messages && messages.length === 0">
							In dieser Konversation sind noch keine Nachrichten versandt worden.
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-lg-6">
			<div class="card">
				<div class="card-header">
					Administration
				</div>
				<div class="card-body">
					<div class="btn btn-primary btn-block" (tap)="editConversationTitle();">Titel ändern</div>
					<div class="btn btn-primary btn-block" (tap)="export();">Konversation exportieren (HTML)</div>
					<div class="btn btn-warning btn-block" *ngIf="!(conversation$ | async).Locked" (tap)="toggleLock();">
						Konversation sperren</div>
					<div class="btn btn-warning btn-block" *ngIf="(conversation$ | async).Locked" (tap)="toggleLock();">
						Konversation entsperren</div>
					<div class="btn btn-danger btn-block" (tap)="removeConversation();">Konversation löschen</div>
				</div>
			</div>
		</div>
	</div>
</div>