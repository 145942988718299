import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import prettyBytes from 'pretty-bytes';
import { ModalService } from 'src/app/service/modal.service';
import { SystemService } from 'src/app/service/system.service';

@Component({
  selector: 'app-page-system',
  templateUrl: './page-system.component.html',
  styleUrls: ['./page-system.component.scss']
})
export class PageSystemComponent implements OnInit {

  diskSpace$ = this.system.diskSpaceState$;

  constructor(
    private system: SystemService,
    private modal: ModalService,
    protected spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
  }

  resetEventSystem() {
    this.modal
      .confirmation('Warnung', 'Diese Aktion reduziert vorübergehend die Performance.<br><br>Sie sollten nur auf Anweisung unseres Supports das EventSystem zurücksetzen.<br><br>Sind Sie sicher, dass Sie das EventSystem zurücksetzen wollen?')
      .subscribe((decision) => {
        if (decision) {
          this.spinner.show();

          this.system.resetEventService()
            .subscribe(() => {
              this.spinner.hide();
            }, (err) => {
              this.spinner.hide();

              this.modal.alert('Fehler', 'Das Backend hat einen Fehler gemeldet. Bitte prüfen Sie die Logs auf dem Server.');
            });
        }
      });
  }

  convertBytesToReadable(bytes: number) {
    return prettyBytes(bytes);
  }

  round(x: number) {
    return Math.round(x);
  }

  diskSpaceLow$() {
    return this.system.diskSpaceLow$();
  }

}
