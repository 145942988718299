export interface IUser {
	_id: string;
	Username: string;
	Email: string;
	Roles: string[];
	CreateDate: string;
	RegisterDate: string;
	LastLogin: string;
	LastAction: string;
	Inactive: boolean;
}

export class UserModel implements IUser {
	public _id: string = null;
	public Username: string;
	public Email: string;
	public Roles: string[];
	public CreateDate: string;
	public RegisterDate: string;
	public LastLogin: string;
	public LastAction: string;
	public Inactive: boolean;
}

export interface IUserDeleteRequestPayload {
	UserID: string;
	KeepMessages: boolean;
	AlternateSenderName: string;
}

export class UserDeleteRequestPayload implements IUserDeleteRequestPayload {
	constructor(
		public UserID: string,
		public KeepMessages: boolean = false,
		public AlternateSenderName: string = null
	) { }
}
