<div class="modal-header">
	<h5 class="modal-title">{{title}}</h5>
</div>
<div class="modal-body">
	<p [innerHTML]="description"></p>		
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-primary" (tap)="close(true);">{{buttonLabels.yes}}</button>
	<button type="button" class="btn btn-secondary" (tap)="close(false);">{{buttonLabels.no}}</button>
</div>
	