import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { IMessage } from '../models/Message.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { ProfileService } from './profile.service';
import { SocketService } from './socket.service';

@Injectable({
	providedIn: 'root'
})
export class ExportService {
	constructor(
		protected profileService: ProfileService,
		protected http: HttpClient,
		protected socket: SocketService,
	) { }

	/**
	 * This method expects the messages to be pre-sorted.
	 *
	 * @param conversation
	 * @param messages
	 */
	exportConversation(ConversationID: string): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:export',
				// sendPayload
				{ ConversationID },
				// successEventName
				'admin:chat:conversation:export:success',
				// failEventName
				'admin:chat:conversation:export:fail'
			).pipe(
				map((event: ISocketEvent): IMessage[] => {
					return event.payload;
				})
			);
	}
}
