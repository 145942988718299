import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faEdit, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { faEye, faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { Observable } from 'rxjs';

import { IChatConversation } from '../../models/ChatConversation.model';
import { ChatService } from '../../service/chat.service';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';

@Component({
	selector: 'app-page-chat-group-list',
	templateUrl: './page-chat-group-list.component.html',
	styleUrls: ['./page-chat-group-list.component.scss']
})
export class PageChatGroupListComponent implements OnInit {

	groupConversations$: Observable<IChatConversation[]> = this.chatService.groupConversations$;
	searchTerm: string;

	icons = {
		users: faUsers,
		search: faSearch,
		edit: faEdit,
		trash: faTrashAlt,
		view: faEye,
		refresh: faSync
	};

	constructor(
		protected userService: UserService,
		protected profileService: ProfileService,
		protected chatService: ChatService,
		protected router: Router
	) {}

	ngOnInit() {}

	formatDate(dateString: string) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	filter(searchTerm: string, conversations: IChatConversation[]): IChatConversation[] {
		if (!searchTerm || searchTerm.length === 0) {
			return conversations;
		}

		return conversations.filter((conversation: IChatConversation): boolean => {
			const terms: string[] = searchTerm.toLowerCase().split(/[ ,]+/);
			const val: string =  conversation.Title.toLocaleLowerCase();

			let result: boolean = false;

			terms.forEach((term: string) => {
				if (val.indexOf(term) !== -1) {
					result = true;
				}
			});

			return result;
		});
	}

	refresh(): void {
		this.userService.refreshUsers();
		this.profileService.refreshProfiles();
		this.chatService.refreshConversations();
	}

	navigateTo(target: string): void {
		this.router.navigate(['main/' + target]);
	}

	navigateToChatDetail(conversation: IChatConversation) {
		console.log(conversation);
		if (!conversation) {
			return;
		}
		this.navigateTo('chat-detail/' + conversation._id);
	}

}
