import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCogs, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faComments, faListAlt, faListUl, faUsers, faWrench } from '@fortawesome/pro-light-svg-icons';
import {
	faBalanceScaleLeft,
	faBook,
	faEnvelope,
	faHandshake,
	faInfoCircle,
	faPowerOff
} from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons';

import { AuthenticationService } from '../../service/authentication.service';
import { ConfigService } from '../../service/config.service';
import { SocketService } from '../../service/socket.service';

@Component({
	selector: 'app-side-menu',
	templateUrl: './side-menu.component.html',
	styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {
	icons = {
		users: faUsers,
		comments: faComments,
		listUl: faListUl,
		plusCircle: faPlusCircle,
		listAlt: faListAlt,
		powerOff: faPowerOff,
		cogs: faCogs,
		wrench: faWrench,
		bcList: faUsersClass,
		info: faInfoCircle,
		docs: faBook,
		email: faEnvelope,
		legal: faBalanceScaleLeft,
		agreements: faHandshake
	};

	apiConfig$ = this.config.apiConfig$;
	printConfig = this.config.getPrintInfo();

	socketState$ = this.socket.connectionState$;

	serverName: string;

	constructor(
		protected router: Router,
		protected auth: AuthenticationService,
		protected config: ConfigService,
		protected socket: SocketService
	) { }

	ngOnInit() {
		const apiConfig = this.apiConfig$.getValue();
		if (apiConfig) {
			this.serverName = apiConfig.name;
		}
	}

	navigateTo(target: string): void {
		this.router.navigate(['main/' + target]);
	}

	logout(): void {
		this.auth.logout();
		setTimeout(() => {
			this.router.navigate(['']);
		}, 300);
	}

	openDocs(): void {
		window.open('https://docs.bico.app/', '_blank');
	}

	supportEmail(): void {
		window.location.href = 'mailto:support@bico.app';
	}
}
