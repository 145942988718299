import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import { ISocketEvent } from '../models/SocketEvent.model';
import { IUserAgreement } from '../models/UserAgreement.model';
import { AppStateService } from './app-state.service';
import { SocketService } from './socket.service';

@Injectable({
	providedIn: 'root'
})
export class UserAgreementService {
	public agreements$: BehaviorSubject<IUserAgreement[]> = new BehaviorSubject<IUserAgreement[]>([]);
	public initComplete$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		protected socket: SocketService,
		protected appStateService: AppStateService
	) {
		this.init();
	}

	protected init(): void {
		this.appStateService
			.refreshTrigger$
			.pipe(
				filter((state: boolean) => {
					return state;
				})
			)
			.subscribe(() => {
				this.refresh();
			});
	}

	refresh(): void {
		this.getAll()
			.subscribe((agreements) => {
				this.agreements$.next(agreements);
				this.initComplete$.next(true);
			});
	}

	getAll(): Observable<IUserAgreement[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:agreement:getAll',
				// sendPayload
				{},
				// successEventName
				'admin:user:agreement:getAll:success',
				// failEventName
				'admin:user:agreement:getAll:fail'
			).pipe(
				map((event: ISocketEvent): IUserAgreement[] => {
					return event.payload;
				})
			);
	}

	create(Title: string, Html: string): Observable<IUserAgreement> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:agreement:create',
				// sendPayload
				{ Title, Html },
				// successEventName
				'admin:user:agreement:create:success',
				// failEventName
				'admin:user:agreement:create:fail'
			).pipe(
				map((event: ISocketEvent): IUserAgreement => {
					return event.payload;
				}),
				tap(() => {
					// Sucess
					this.refresh();
				})
			);
	}

	update(AgreementID: string, Title: string, Html: string): Observable<IUserAgreement> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:agreement:update',
				// sendPayload
				{ AgreementID, Title, Html },
				// successEventName
				'admin:user:agreement:update:success',
				// failEventName
				'admin:user:agreement:update:fail'
			).pipe(
				map((event: ISocketEvent): IUserAgreement => {
					return event.payload;
				}),
				tap(() => {
					// Sucess
					this.refresh();
				})
			);
	}

	delete(AgreementID: string): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:agreement:delete',
				// sendPayload
				{ AgreementID },
				// successEventName
				'admin:user:agreement:delete:success',
				// failEventName
				'admin:user:agreement:delete:fail'
			).pipe(
				map((event: ISocketEvent): IUserAgreement => {
					return event.payload;
				}),
				tap(() => {
					// Sucess
					this.refresh();
				})
			);
	}

	observeByID(AgreementID: string): null | Observable<IUserAgreement> {
		if (!this.doesIDExist(AgreementID)) {
			return null;
		}

		return this.agreements$.pipe(
			map((agreements: IUserAgreement[]) => {
				return agreements.find((agreement) => {
					return agreement._id === AgreementID;
				});
			})
		);
	}

	protected doesIDExist(AgreementID: string): boolean {
		const agreements = this.agreements$.getValue();

		const index = agreements.findIndex((list) => {
			return list._id === AgreementID;
		});

		return index !== -1;
	}

}
