import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { IPickerOption } from '../../modals/modal-picker/picker-option.interface';
import { IErrorEvent } from '../../models/ErrorEvent.model';
import { IUser } from '../../models/User.model';
import { ChatService } from '../../service/chat.service';
import { ModalService } from '../../service/modal.service';
import { UserService } from '../../service/user.service';

@Component({
	selector: 'app-page-chat-group-create',
	templateUrl: './page-chat-group-create.component.html',
	styleUrls: ['./page-chat-group-create.component.scss']
})
export class PageChatGroupCreateComponent implements OnInit {
	users: IPickerOption[] = [];

	icons = {
		plusCircle: faPlusCircle
	};

	title = '';
	description = '';

	@ViewChild('createGroupForm')
	form: NgForm;

	constructor(
		protected modalService: ModalService,
		protected userService: UserService,
		protected chatService: ChatService,
		protected spinner: NgxSpinnerService
	) { }

	ngOnInit() {
		this.setupUsers();
	}

	reset() {
		this.title = '';
		this.description = '';
		this.setupUsers();

		this.form.reset();
	}

	submit() {
		this.spinner.show();
		this.chatService.createConversation(
			this.title,
			this.description,
			this.getSelectedUserIDs()
		).subscribe(() => {
			this.spinner.hide();
			alert(`Erfolg! Die Gruppe "${this.title}" wurde erstellt.`);
			this.reset();
		}, (err: IErrorEvent) => {
			this.spinner.hide();
			alert(`Fehler: Die Gruppe konnte nicht erstellt werden. Code: ${err.status} - ${err.message}`);
		});
	}

	protected getSelectedUserIDs(): string[] {
		const userIDs: string[] = this.getEnabledOptions()
			.map((option: IPickerOption) => {
				return option.value;
			});

		return userIDs;
	}

	manageParticipants(): void {
		const title = `Teilnehmer auswählen`;
		const description = `Markieren Sie die Benutzer, die Teilnehmer dieser Konversation sein sollen.`;

		this.modalService
			.picker(this.users, title, description)
			.subscribe((result: IPickerOption[]) => {
				this.users = result;
			}, (err: any) => {
				console.log('closed with error', err);
			});
	}

	protected setupUsers(): void {
		const allUsers: IUser[] = this.userService.users$.getValue();

		// Generate options for the picker
		this.users = allUsers.map((user: IUser): IPickerOption => {
			return {
				label: user.Username,
				enabled: false,
				value: user._id
			};
		});
	}

	getEnabledOptions(): IPickerOption[] {
		return this.users.filter((option: IPickerOption) => {
			return option.enabled;
		});
	}

}
