<div page-user-print>
	<div class="text-center" no-print>
		<button class="btn btn-primary" (tap)="goBack();">
			Fertig
		</button>
	</div>
	<div header>
		<img logo [src]="printInfo.print_logo">
	</div>
	<div class="container-fluid">
		<div class="row" top-spacing>
			<div class="col col-12">
				<h1>Herzlich Willkommen bei der "{{printInfo.name}}"</h1>
				<p>Wir freuen uns, Sie in unserer App begrüßen zu dürfen. <span *ngIf="printInfo.name === 'EE App'">Innerhalb
						der App gibt es einen Support-Chat, den Sie jederzeit gern Nutzen können um Fragen zu stellen, uns Ihre
						Erlebnisse mit der App mitzuteilen oder Anregungen zu geben.</span> Bei Fragen oder technischen Problemen
					wenden Sie sich bitte an Ihren Administrator.</p>
			</div>
		</div>
		<div class="row" top-spacing>
			<div class="col col-4">
				<h1>I. Nutzerdaten</h1>

				<div class="login-detail" *ngIf="printInfo.showServerCodeInPrint">
					<label>Verbindungscode:</label>
					{{(apiConfig$ | async).code}}
				</div>
				<div class="login-detail">
					<label>Name:</label>
					{{lastname}}, {{firstname}}
				</div>
				<div class="login-detail">
					<label>Benutzername:</label>
					{{username}}
				</div>
				<div class="login-detail">
					<label>Password:</label>
					{{password}}
				</div>
			</div>
			<div class="col col-8">
				<h1>II. Nutzerdaten</h1>
				<p>Nutzen Sie bitte die auf der linken Seite aufgefühten Nutzerdaten zum Login. Wir empfehlen das Passwort
					schnellstmöglich zu ändern. Bitte teilen Sie ihre Zugangsdaten niemals einer dritten Person mit. Auch unser
					Support wird Sie niemals danach fragen.</p>
				<h1>III. Datenschutz</h1>
				<p>Der Datenschutz steht bei der {{printInfo.name}} im Vordergrund. Sämtliche Daten werden auf einem geschützten
					Server mit deutschem Standort gespeichert und niemals für Werbezwecke ausgewertet. Darüberhinaus setzen wir
					auch keine Cookies oder Tracking ein. Diese App wird von Ihrem Arbeitgeber zur Verfügung gestellt. Die Nutzung
					ist ausschließlich für betriebliche Zwecke gestattet. Alle Vorgänge inkl. der Inhalte der Kommunikation werden
					zur Dokumentation archiviert. </p>
			</div>
		</div>
		<div class="row" top-spacing>
			<div class="col col-12">
				<h1>IV. Informationen zum Download</h1>
				<p>Die {{printInfo.name}} steht für Android, iOS, Windows und macOS bereit. Öffnen Sie einfach auf Ihrem
					Smartphone oder PC die Website {{printInfo.web_url_prefix}} und klicken Sie auf einen der Buttons. Für
					Smartphones öffnet sich dann der entsprechende Store. Für PCs und Macs startet der Download sofort.</p>
			</div>
		</div>

		<div class="row" download-area top-spacing>
			<div class="col col-4">
				<img qr [src]="printInfo.qr_code">
			</div>
			<div class="col col-8">
				<div class="row" banner-area>
					<div class="col-6">
						<img src="assets/print/AppStore.png" download-banner>
					</div>
					<div class="col-6">
						<img src="assets/print/Mac.png" download-banner>
					</div>
				</div>
				<div class="row">
					<div class="col-6">
						<img src="assets/print/GooglePlay.png" download-banner>
					</div>
					<div class="col-6">
						<img src="assets/print/Windows.png" download-banner>
					</div>
				</div>
				<div class="row">
					<div class="col-12">
						<div main-url>
							{{printInfo.web_url_prefix}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row" url-area top-spacing>
			<div class="col-12">
				<div secondary-url>
					<label>AppStore:</label>
					{{printInfo.appStore_url}}
				</div>
				<div secondary-url>
					<label>PlayStore:</label>
					{{printInfo.playStore_url}}
				</div>
				<div secondary-url>
					<label>Windows:</label>
					{{printInfo.web_url_prefix}}
				</div>
				<div secondary-url>
					<label>macOS:</label>
					{{printInfo.web_url_prefix}}
				</div>
			</div>
		</div>
	</div>
</div>