<div alert-area *ngIf="(connectionState$ | async) === 'disconnected' && (config.serverCode$ | async) !== ''">
	<ngb-alert [dismissible]="false" type="danger">
		<div style="text-align: center;">
			<strong>Warnung!</strong> Es besteht keine Verbindung zum Server.
		</div>
	</ngb-alert>
</div>

<div class="container-fluid">
	<router-outlet></router-outlet>
</div>