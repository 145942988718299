import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { faSync } from '@fortawesome/pro-regular-svg-icons';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import { ExportService } from 'src/app/service/export.service';
import { SocketService } from 'src/app/service/socket.service';
import { environment } from 'src/environments/environment';

import { convertObservableToBehaviorSubject } from '../../../util/convertObservableToBehaviorSubject';
import { getTransformationFileURL } from '../../../util/fileTransformationHelper';
import { sortMessagesByDate } from '../../../util/sortMessagesByDate';
import { IPickerOption } from '../../modals/modal-picker/picker-option.interface';
import { IChatConversation } from '../../models/ChatConversation.model';
import { IMessage } from '../../models/Message.model';
import { IProfile } from '../../models/Profile.model';
import { IUser } from '../../models/User.model';
import { ChatService } from '../../service/chat.service';
import { ModalService } from '../../service/modal.service';
import { ProfileService } from '../../service/profile.service';
import { UserService } from '../../service/user.service';

@Component({
	selector: 'app-page-chat-detail',
	templateUrl: './page-chat-detail.component.html',
	styleUrls: ['./page-chat-detail.component.scss']
})
export class PageChatDetailComponent implements OnInit {

	hideChatHistory: boolean = !!environment.disableChatView;

	conversationID: string;
	conversation$: BehaviorSubject<IChatConversation>;
	participantProfiles$: BehaviorSubject<IProfile[]>;
	messages: IMessage[];
	allMessages: IMessage[];

	icons = {
		refresh: faSync
	};

	@ViewChild('scrollContainer')
	private scrollContainer: ElementRef;

	constructor(
		protected route: ActivatedRoute,
		protected location: Location,
		protected chatService: ChatService,
		protected profileService: ProfileService,
		protected userService: UserService,
		protected modalService: ModalService,
		protected spinner: NgxSpinnerService,
		protected exportService: ExportService,
		protected socket: SocketService
	) { }

	ngOnInit() {
		this.route.params
			.pipe(take(1))
			.subscribe((params: Params) => {
				if (params.ConversationID) {
					this.conversationID = params.ConversationID;
					this.gatherData(this.conversationID);
				}
			});
	}

	gatherData(conversationID: string): void {
		const observable = this.chatService
			.getConversationByID(conversationID)
			.pipe(tap((conversation: IChatConversation): void => {
				this.getProfiles(conversation.Participants);
			}));

		this.conversation$ = convertObservableToBehaviorSubject(observable);

		this.refreshMessages(conversationID);
	}

	refreshMessages(conversationID: string) {
		this.socket
			.connectionState$
			.pipe(
				filter(state => state === 'connected'),
				take(1)
			).subscribe(() => {
				this._refreshMessages(conversationID);
			});
	}

	_refreshMessages(conversationID) {
		this.chatService
			.getMessages(conversationID)
			.pipe(
				map((messages: IMessage[]): IMessage[] => {
					// Only get the last 50 messages (for performance reasons)
					// TODO: Remove if virtual scroll has been implemented
					return sortMessagesByDate(messages);
				}),
				tap((): void => {
					this.scrollChatHistoryToBottom();
				})
			).subscribe((messages: IMessage[]): void => {
				this.allMessages = messages;
				this.messages = messages.slice(-100);
			});
	}

	getProfiles(participantIDs: string[]): void {
		const observable = this.profileService
			.getProfiles(participantIDs);

		this.participantProfiles$ = convertObservableToBehaviorSubject(observable);
	}

	isUserParticipant(UserID): boolean {
		return this.conversation$.getValue().Participants.includes(UserID);
	}

	editParticipants(): void {
		const allUsers: IUser[] = this.userService.users$.getValue();

		// Generate options for the picker
		const options: IPickerOption[] = allUsers.map((user: IUser): IPickerOption => {
			return {
				label: user.Username,
				enabled: this.isUserParticipant(user._id),
				value: user._id
			};
		});

		const title = `Teilnehmer auswählen`;
		const description = `Markieren Sie die User, die Teilnehmer dieser Konversation sein sollen.`;

		this.modalService
			.picker(options, title, description)
			.subscribe((result: IPickerOption[]) => {
				const userIDs: string[] = result.filter((option: IPickerOption) => {
					return option.enabled;
				}).map((option: IPickerOption) => {
					return option.value;
				});

				this.chatService.updateConversationParticipats(this.conversationID, userIDs).subscribe();
			}, (err: any) => {
				console.log('closed with error', err);
			});
	}

	editConversationTitle() {
		const currentTitle: string = this.conversation$.getValue().Title;

		this.modalService.prompt(
			'Titel ändern',
			'Bitte geben Sie den neuen Titel ein.',
			'Titel',
			currentTitle,
			str => str.length > 0
		).subscribe((newTitle: string) => {
			this.spinner.show();

			this.chatService.updateConversationTitle(
				this.conversationID,
				newTitle
			).subscribe(() => {
				// Success
				this.spinner.hide();
			}, (err) => {
				this.spinner.hide();
				this.modalService
					.alert(
						'Fehler',
						`Der Titel könnte nicht geändert werden. (Fehlercode: ${err.status})`
					);
			});
		});
	}

	getAvatarURL(conversation: IChatConversation | IProfile): string {
		const palceholderURL = 'assets/default_avatar.svg';

		if (!conversation || !conversation.AvatarFile) {
			return palceholderURL;
		}

		const url = getTransformationFileURL(conversation.AvatarFile, 'mini');

		return url ? url : palceholderURL;
	}

	formatDate(dateString: string) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	scrollChatHistoryToBottom(): void {
		setTimeout(() => {
			try {
				this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollHeight;
			} catch (err) { }
		});
	}

	toggleLock(): void {
		const conversation = this.conversation$.getValue();

		let confirmation_title = 'Konvesation sperren';
		let confirmation_text = `
			Möchten Sie die Konversation "${this.conversation$.getValue().Title}" sperren?<br>
			Benutzer können dann keine Nachrichten mehr in dieser Gruppe versenden.
		`;

		if (conversation.Locked) {
			confirmation_title = 'Konversation entsperren';
			confirmation_text = `Möchten Sie die Konversation "${this.conversation$.getValue().Title}" entsperren?`;
		}

		this.modalService
			.confirmation(
				confirmation_title,
				confirmation_text
			).subscribe((decision: boolean) => {
				if (decision === true) {
					this.spinner.show();
					if (!conversation.Locked) {
						this.chatService.lockConversation(this.conversationID).subscribe(() => {
							this.spinner.hide();
						});
					} else {
						this.chatService.unlockConversation(this.conversationID).subscribe(() => {
							this.spinner.hide();
						});
					}
				}
			});
	}

	removeConversation(): void {
		this.modalService
			.confirmation(
				'Konvesation löschen',
				`Möchten Sie die Konversation "${this.conversation$.getValue().Title}" löschen?`
			).subscribe((decision: boolean) => {
				if (!decision) {
					return;
				}

				this.chatService.removeConversation(this.conversation$.getValue()._id)
					.subscribe(() => {
						this.location.back();
					}, (err: any) => {
						alert(`Fehler: Die Gruppe konnte nicht gelöscht werden. Code: ${err.status} - ${err.message}`);
					});
			});
	}

	updateAvatarBase64(imgBase64: string) {
		this.spinner.show();

		this.chatService
			.updateConversationAvatar(this.conversationID, imgBase64)
			.subscribe((response: any) => {
				// Success
				this.spinner.hide();
			}, (err: any) => {
				this.spinner.hide();

				alert(`Fehler: Das Gruppenbild konnte nicht geändert werden. Code: ${err.status} - ${err.message}`);
			});
	}

	export() {
		const conversation = this.conversation$.getValue();

		this.spinner.show();
		this.exportService
			.exportConversation(
				conversation._id,
			).subscribe((result: any) => {

				this.spinner.hide();

				alert('Export erfolgreich.\nBitte prüfen Sie Ihren "Downloads"-Ordner.');

				window.location.href = `${result.downloadURL}/chat-export.zip`;

			}, (err) => {
				alert(`Es ist ein Fehler aufgetreten. (Code: ${err.status})`);
				console.error(err);
			});
	}
}
