import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { faHandshake, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faFileExclamation } from '@fortawesome/pro-regular-svg-icons';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { IUserAgreement } from 'src/app/models/UserAgreement.model';
import { UserAgreementService } from 'src/app/service/user-agreement.service';
import { convertObservableToBehaviorSubject } from 'src/util/convertObservableToBehaviorSubject';

@Component({
	selector: 'app-page-user-agreement-detail',
	templateUrl: './page-user-agreement-detail.component.html',
	styleUrls: ['./page-user-agreement-detail.component.scss']
})
export class PageUserAgreementDetailComponent implements OnInit {

	AgreementID: string;
	agreement$: BehaviorSubject<IUserAgreement>;

	agreement: IUserAgreement = { Title: '', Html: '', Version: 0 };

	icons = {
		agreement: faHandshake,
		info: faInfoCircle,
		document: faFileExclamation
	};

	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: false,
		height: '40vh',
		sanitize: false,
		toolbarHiddenButtons: [
			['insertImage', 'insertVideo']
		]
	};

	constructor(
		protected route: ActivatedRoute,
		protected agreementService: UserAgreementService,
		protected spinner: NgxSpinnerService,
		protected router: Router
	) {

	}

	ngOnInit() {
		this.route.params
			.pipe(take(1))
			.subscribe((params: Params) => {
				if (!params.AgreementID) {
					return;
				}

				this.AgreementID = params.AgreementID;
				this.agreement$ = convertObservableToBehaviorSubject(this.agreementService.observeByID(this.AgreementID));

				this.agreement = this.agreement$.getValue();
			});
	}

	afterSubmit() {
		this.router.navigate(['main/user-agreement-list']);
	}

	submit() {
		this.spinner.show();

		this.agreementService
			.update(this.agreement._id, this.agreement.Title, this.agreement.Html)
			.subscribe(() => {
				this.spinner.hide();
				alert(`Erfolg! Die Vereinbarung "${this.agreement.Title}" wurde aktualisiert.`);
				this.afterSubmit();
			}, (err) => {
				this.spinner.hide();
				alert(`Fehler: Die Vereinbarung konnte nicht aktualisiert werden. Code: ${err.status} - ${err.message}`);
			});
	}

}
