import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';
import * as Croppie from 'croppie';

/**
 * This Class is heavily inspired by:
 * https://github.com/deej81/ngx-croppie/blob/master/src/app/modules/ngx-croppie/ngx-croppie.component.ts
 */
@Component({
	selector: 'app-ngx-croppie',
	templateUrl: './ngx-croppie.component.html',
	styleUrls: ['./ngx-croppie.component.scss']
})
export class NgxCroppieComponent implements OnInit {
	// DOM Element Refs
	@ViewChild('croppieElement')
	croppieElement: ElementRef;

	// Inputs
	@Input()
	options: Croppie.CroppieOptions = {};

	@Input()
	outputSize: { height: number, width: number } = { height: 400, width: 400 };

	@Input()
	defaultZoom = 0; // number between 0 and 1 (e.g. 0.5)

	@Input()
	set imageUrl(url: string) {
		if (this._imgUrl === url && !!url) {
			// if the url hasn't changed, do nothing;
			return;
		}
		// Update internal url
		this._imgUrl = url;

		// Update croppie
		if (this.croppie) {
			this.bindToCroppie(this._imgUrl, this.points, this.defaultZoom);
		}
	}

	@Input()
	points: number[]; // Array of points that translate into [topLeftX, topLeftY, bottomRightX, bottomRightY]

	// Outputs
	@Output()
	result: EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>
		= new EventEmitter<string | HTMLElement | Blob | HTMLCanvasElement>();

	// Private class properties
	protected croppie: Croppie;
	protected _imgUrl: string;

	constructor() { }

	ngOnInit() {
		setTimeout(() => {
			this.initCroppie();
		});
	}

	initCroppie() {
		this.croppie = new Croppie(this.croppieElement.nativeElement, this.options);
		this.bindToCroppie(this._imgUrl, this.points, this.defaultZoom);
	}

	// Apply image URL to Croppie
	protected bindToCroppie(url: string, points: number[], zoom: number) {
		this.croppie.bind({ url, points, zoom });
	}

	// This method is called from the template
	/**
	 * Emit new base64 image result
	 *
	 */
	newResult() {
		const outputFormatOptions: Croppie.ResultOptions = {
			type: 'base64',
			size: this.outputSize,
			circle: false
		};

		this.croppie.result(outputFormatOptions).then((res) => {
			this.result.emit(res);
		});
	}

	/*
	// Rotation only works if exif.js is added to the project.

	public rotate(degrees: 90 | 180 | 270 | -90 | -180 | -270) {
		this.croppie.rotate(degrees);
	}

	public rotateLeft() {
		this.rotate(-90);
	}

	public rotateRight() {
		this.rotate(90);
	}
	*/

}
