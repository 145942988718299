<ngx-spinner
	size="medium"
	type="timer"
>
	<p style="font-size: 20px; color: white">Bitte warten...</p>
</ngx-spinner>


<div page page-user-create>
	<div class="row">
		<div class="col-6 offset-3">
			<div class="card">
				<div class="card-header">
					<fa-icon [icon]="icons.plus"></fa-icon> Verteilerliste erstellen
				</div>
				<div class="card-body">
					<form #createForm="ngForm" novalidate>
						<div class="form-group">
							<label>Titel</label>
							<input type="text" class="form-control" [(ngModel)]="title" name="title" #titleField="ngModel" [ngClass]="{'is-invalid': titleField.invalid &&  titleField.touched}" placeholder="Bitte eingeben" minlength="2" required>
						</div>
						<label>Empfänger</label><br>
						<div *ngIf="users.length > 0"><span class="badge badge-pill badge-primary" *ngFor="let user of getEnabledOptions()">{{user.label}}</span></div>
						<div class="btn btn-link" (tap)="manageReceivers();"><span *ngIf="users.length === 0">Hinzufügen</span><span *ngIf="users.length !== 0">Ändern</span></div>
						<br>
						<br>
						<button type="submit" class="btn btn-primary" (click)="submit();" [disabled]="createForm.invalid">Erstellen</button>
					</form>
				</div>
			</div>
		</div>
	</div>
</div>