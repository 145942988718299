import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { IChatConversation } from '../models/ChatConversation.model';
import { IErrorEvent } from '../models/ErrorEvent.model';
import { IMessage } from '../models/Message.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { AppStateService } from './app-state.service';
import { AuthenticationService } from './authentication.service';
import { SocketService } from './socket.service';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root'
})
export class ChatService {

	public groupConversations$: BehaviorSubject<IChatConversation[]> = this.store.getSubject('chatService:conversationGroups', null);

	constructor(
		protected socket: SocketService,
		protected auth: AuthenticationService,
		protected store: StoreService,
		protected appStateService: AppStateService
	) {
		this.appStateService.refreshTrigger$
			.pipe(
				filter((state: boolean) => {
					return state;
				})
			)
			.subscribe(() => {
				this.refreshConversations();
			});
	}

	public getAllGroupConversations(): Observable<IChatConversation[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversations:getAllGroups',
				// sendPayload
				{},
				// successEventName
				'admin:chat:conversations:getAllGroups:success',
				// failEventName
				'admin:chat:conversations:getAllGroups:fail'
			).pipe(
				map((event: ISocketEvent): IChatConversation[] => {
					return event.payload;
				})
			);
	}

	public getMessages(ConversationID: string): Observable<IMessage[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:getMessages',
				// sendPayload
				{ ConversationID },
				// successEventName
				'admin:chat:conversation:getMessages:success',
				// failEventName
				'admin:chat:conversation:getMessages:fail'
			).pipe(
				map((event: ISocketEvent): IMessage[] => {
					return event.payload;
				})
			);
	}

	public getConversationsForUser(UserID: string): Observable<IChatConversation[]> {
		return this.groupConversations$
			.pipe(
				filter((conversations: IChatConversation[]): boolean => {
					return conversations !== null;
				}),
				map((conversations: IChatConversation[]) => {
					return conversations.filter((conversation: IChatConversation): boolean => {
						return conversation.Participants.includes(UserID);
					});
				})
			);
	}

	public getConversationByID(ConversationID: string): Observable<IChatConversation> {
		return this.groupConversations$
			.pipe(
				filter((conversations: IChatConversation[]): boolean => {
					return conversations !== null;
				}),
				map((conversations: IChatConversation[]) => {
					return conversations.find((conversation: IChatConversation): boolean => {
						return conversation._id === ConversationID;
					});
				})
			);
	}

	public updateConversationTitle(ConversationID: string, Title: string): Observable<IChatConversation> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:updateTitle',
				// sendPayload
				{ ConversationID, Title },
				// successEventName
				'admin:chat:conversation:updateTitle:success',
				// failEventName
				'admin:chat:conversation:updateTitle:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public updateUserParticipations(UserID: string, ConversationIDs: string[]): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:user:updateParticipations',
				// sendPayload
				{ UserID, ConversationIDs },
				// successEventName
				'admin:chat:user:updateParticipations:success',
				// failEventName
				'admin:chat:user:updateParticipations:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public updateConversationParticipats(ConversationID: string, UserIDs: string[]): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:updateParticipants',
				// sendPayload
				{ ConversationID, UserIDs },
				// successEventName
				'admin:chat:conversation:updateParticipants:success',
				// failEventName
				'admin:chat:conversation:updateParticipants:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}


	public createConversation(Title: string, Description: string, Participants: string[]): Observable<IChatConversation> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:create',
				// sendPayload
				{ Title, Description, Participants },
				// successEventName
				'admin:chat:conversation:create:success',
				// failEventName
				'admin:chat:conversation:create:fail'
			).pipe(
				map((event: ISocketEvent): IChatConversation => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public removeConversation(ConversationID: string): Observable<boolean> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:remove',
				// sendPayload
				{ ConversationID },
				// successEventName
				'admin:chat:conversation:remove:success',
				// failEventName
				'admin:chat:conversation:remove:fail'
			).pipe(
				map((event: ISocketEvent): boolean => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public lockConversation(ConversationID: string): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:lock',
				// sendPayload
				{ ConversationID },
				// successEventName
				'admin:chat:conversation:lock:success',
				// failEventName
				'admin:chat:conversation:lock:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public unlockConversation(ConversationID: string): Observable<any> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:unlock',
				// sendPayload
				{ ConversationID },
				// successEventName
				'admin:chat:conversation:unlock:success',
				// failEventName
				'admin:chat:conversation:unlock:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public updateConversationAvatar(ConversationID, imgBase64: string): Observable<IChatConversation> {
		const base64result = imgBase64.split(',');
		const AvatarBase64Prefix = base64result[0] + ','; // Append comma, because it's removed by the split method
		const AvatarBase64Data = base64result[1];

		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:conversation:update:avatar',
				// sendPayload
				{ ConversationID, AvatarBase64Prefix, AvatarBase64Data },
				// successEventName
				'admin:chat:conversation:update:avatar:success',
				// failEventName
				'admin:chat:conversation:update:avatar:fail'
			).pipe(
				map((event: ISocketEvent): any => {
					return event.payload;
				}),
				tap((): void => {
					// Since the data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);

	}

	public refreshConversations() {
		if (this.auth.socketAuthState$.getValue() === false) {
			return;
		}

		this.getAllGroupConversations()
			.subscribe((conversations: IChatConversation[]) => {
				this.groupConversations$.next(this.sortConversations(conversations));
			}, (err: IErrorEvent) => {
				alert(`Fehler: Chat Konversationen konnten nicht geladen werden. Code:  ${err.status} | ${err.message} `);
			});
	}

	protected sortConversations(conversations: IChatConversation[]): IChatConversation[] {
		return conversations.sort((a: IChatConversation, b: IChatConversation): number => {
			if (a.Title < b.Title) { return -1; }
			if (a.Title > b.Title) { return 1; }
			return 0;
		});
	}
}
