import * as moment from 'moment';
import { IMessage } from '../app/models/Message.model';

export function sortMessagesByDate(messages: IMessage[], order: 'ASC' | 'DESC' = 'DESC'): IMessage[] {
	return messages.sort((a: IMessage, b: IMessage) => {
		const dateA = moment(a.Created);
		const dateB = moment(b.Created);

		if (order === 'ASC') {
			return dateB.diff(dateA);
		} else {
			return dateA.diff(dateB);
		}
	});
}
