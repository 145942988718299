import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { IErrorEvent } from '../models/ErrorEvent.model';
import { IProfile } from '../models/Profile.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { AppStateService } from './app-state.service';
import { AuthenticationService } from './authentication.service';
import { SocketService } from './socket.service';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root'
})
export class ProfileService {

	public profiles$: BehaviorSubject<IProfile[]> = this.store.getSubject('profileService:profiles', null);

	constructor(
		protected socket: SocketService,
		protected auth: AuthenticationService,
		protected store: StoreService,
		protected appStateService: AppStateService
	) {
		this.appStateService.refreshTrigger$
			.pipe(
				filter((state: boolean) => {
					return state;
				})
			)
			.subscribe(() => {
				this.refreshProfiles();
			});

		this.auth
			.authState$
			.subscribe((authenticated: boolean) => {
				if (!authenticated) {
					this.profiles$.next(null);
				}
			});
	}

	public getAll(): Observable<IProfile[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:profile:getAll',
				// sendPayload
				{},
				// successEventName
				'admin:profile:getAll:success',
				// failEventName
				'admin:profile:getAll:fail'
			).pipe(
				map((event: ISocketEvent): IProfile[] => {
					return event.payload;
				})
			);
	}

	/**
	 * Either emits the users Profile or null if not found
	 *
	 * @param UserID
	 */
	public getProfile(UserID: string): Observable<IProfile> {
		return this.profiles$
			.pipe(
				filter((profiles: IProfile[]): boolean => {
					return profiles !== null;
				}),
				map((profiles: IProfile[]): IProfile => {
					return profiles.find((profile: IProfile): boolean => {
						return profile.UserID === UserID;
					});
				})
			);
	}


	/**
	 * Either emits the user Profile or null if not found
	 *
	 * @param UserIDs
	 */
	public getProfiles(UserIDs: string[]): Observable<IProfile[]> {
		return this.profiles$
			.pipe(
				filter((profiles: IProfile[]): boolean => {
					return profiles !== null;
				}),
				map((profiles: IProfile[]): IProfile[] => {
					return profiles.filter((profile: IProfile): boolean => {
						return UserIDs.includes(profile.UserID);
					});
				})
			);
	}

	public getMultipleByID(UserIDs: string[]): IProfile[] {
		return this.profiles$.getValue()
			.filter((profile: IProfile): boolean => {
				return UserIDs.includes(profile.UserID);
			});
	}

	public refreshProfiles(): void {
		if (this.auth.socketAuthState$.getValue() === false) {
			return;
		}

		this.getAll()
			.subscribe((profiles: IProfile[]) => {
				this.profiles$.next(this.sortProfiles(profiles));
			}, (err: IErrorEvent) => {
				alert(`Fehler: Profile konnten nicht geladen werden. Code:  ${err.status} | ${err.message} `);
			});
	}

	protected sortProfiles(users: IProfile[]): IProfile[] {
		return users.sort((a: IProfile, b: IProfile): number => {
			if (a.Name < b.Name) { return -1; }
			if (a.Name > b.Name) { return 1; }
			return 0;
		});
	}
}
