import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { MainFrameComponent } from './pages/main-frame/main-frame.component';
import { PageBroadcastCreateComponent } from './pages/page-broadcast-create/page-broadcast-create.component';
import { PageBroadcastDetailComponent } from './pages/page-broadcast-detail/page-broadcast-detail.component';
import { PageBroadcastListComponent } from './pages/page-broadcast-list/page-broadcast-list.component';
import { PageChatDetailComponent } from './pages/page-chat-detail/page-chat-detail.component';
import { PageChatGroupCreateComponent } from './pages/page-chat-group-create/page-chat-group-create.component';
import { PageChatGroupListComponent } from './pages/page-chat-group-list/page-chat-group-list.component';
import { PageCreateAdminComponent } from './pages/page-create-admin/page-create-admin.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageSystemComponent } from './pages/page-system/page-system.component';
import { PageUserAgreementCreateComponent } from './pages/page-user-agreement-create/page-user-agreement-create.component';
import { PageUserAgreementDetailComponent } from './pages/page-user-agreement-detail/page-user-agreement-detail.component';
import { PageUserAgreementListComponent } from './pages/page-user-agreement-list/page-user-agreement-list.component';
import { PageUserCreateComponent } from './pages/page-user-create/page-user-create.component';
import { PageUserDetailComponent } from './pages/page-user-detail/page-user-detail.component';
import { PageUserListComponent } from './pages/page-user-list/page-user-list.component';
import { PageUserPrintComponent } from './pages/page-user-print/page-user-print.component';
import { PageWelcomeComponent } from './pages/page-welcome/page-welcome.component';

const appRoutes: Routes = [
	{ path: 'login', component: PageLoginComponent },
	{ path: 'create-admin', component: PageCreateAdminComponent },
	{
		path: 'main',
		component: MainFrameComponent,
		canActivate: [
			AuthGuard
		],
		canActivateChild: [
			AuthGuard
		],
		children: [
			{ path: '', component: PageWelcomeComponent },
			{ path: 'user-list', component: PageUserListComponent },
			{ path: 'user-create', component: PageUserCreateComponent },
			{ path: 'user-detail/:UserID', component: PageUserDetailComponent },
			{ path: 'user-agreement-list', component: PageUserAgreementListComponent },
			{ path: 'user-agreement-create', component: PageUserAgreementCreateComponent },
			{ path: 'user-agreement-detail/:AgreementID', component: PageUserAgreementDetailComponent },
			{ path: 'chat-group-create', component: PageChatGroupCreateComponent },
			{ path: 'chat-group-list', component: PageChatGroupListComponent },
			{ path: 'chat-detail/:ConversationID', component: PageChatDetailComponent },
			{ path: 'chat-broadcast-list', component: PageBroadcastListComponent },
			{ path: 'chat-broadcast-detail/:ListID', component: PageBroadcastDetailComponent },
			{ path: 'chat-broadcast-create', component: PageBroadcastCreateComponent },
			{ path: 'system', component: PageSystemComponent }
		]
	},
	{ path: 'user-print', component: PageUserPrintComponent, canActivate: [AuthGuard] },
	{ path: '', redirectTo: '/login', pathMatch: 'full' },
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [
		RouterModule.forRoot(
			appRoutes,
			{ enableTracing: false } // <-- debugging purposes only
		)
	],
	providers: [],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule { }
