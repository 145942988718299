import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

import { distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthenticationService } from './service/authentication.service';
import { ConfigService } from './service/config.service';
import { SocketService } from './service/socket.service';
import { SystemService } from './service/system.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'admin-ui';

	connectionState$ = this.socket.connectionState$;

	constructor(
		protected socket: SocketService,
		protected auth: AuthenticationService,
		protected router: Router,
		protected titleService: Title,
		public config: ConfigService,
		protected system: SystemService
	) {
		this.initizalizeApp();
	}

	initizalizeApp(): void {
		this.socket.init();
		this.goToLoginOnLogout();
		/*this.socket.establishConnection();
		this.auth.init();
		this.titleService.setTitle( `${this.config.getAppConf().name} Admin`  );*/

		this.observeInitState();
	}

	protected navigateTo(target: string): void {
		this.router.navigate([target]);
	}

	protected goToLoginOnLogout() {
		this.auth.authState$
			.pipe(
				// Only trugger when the state has changed
				distinctUntilChanged((before: boolean, after: boolean) => {
					return before === after;
				}),
				// Only trigger when the user is not logged in
				filter((isLoggedIn: boolean) => {
					return !isLoggedIn && isLoggedIn !== undefined;
				})
			)
			// Go to the root page of the app
			.subscribe(() => {
				this.navigateTo('/login');
			});
	}

	protected observeInitState() {
		this.socket
			.observe('admin:initState')
			.subscribe(() => {
				this.router.navigate(['create-admin']);
			});
	}
}
