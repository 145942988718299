import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/service/authentication.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-main-frame',
	templateUrl: './main-frame.component.html',
	styleUrls: ['./main-frame.component.scss']
})
export class MainFrameComponent implements OnInit {

	constructor(
		protected auth: AuthenticationService,
		protected router: Router
	) { }

	ngOnInit() {
		this.auth.authState$
			.subscribe((authState: boolean) => {
				console.log('AUTH STATE', authState);
				if (!authState) {
					this.router.navigate(['']);
				}
			});
	}

}
