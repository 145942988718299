import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-confirmation',
	templateUrl: './modal-confirmation.component.html',
	styleUrls: ['./modal-confirmation.component.scss']
})
export class ModalConfirmationComponent implements OnInit {

	public buttonLabels = {
		yes: 'Ja',
		no: 'Nein'
	};

	public title: string;
	public description: string;

	constructor(
		protected activeModal: NgbActiveModal
	) { }

	ngOnInit() {
	}

	close(result: boolean) {
		this.activeModal.close(result);
	}

}
