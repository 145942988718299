export interface ISocketEvent {
	eventName: string;
	payload: any;
}

export class SocketEventModel implements ISocketEvent {
	public eventName: string;
	public payload: any;

	constructor(eventName?: string, payload?: any) {
		this.eventName = eventName;
		this.payload = payload;
	}
}
