<div page page-welcome>

	<div class="row">
		<div class="col-md-12 col-lg-6 offset-lg-3 ">
			<div class="alert alert-danger" role="alert" *ngIf="(diskSpaceLow$() | async)">
				<strong>ACHTUNG!</strong><br>
				Der Speicherplatz auf Ihrem Server wird knapp!<br>
				Sie müssen dieses Problem umgehend beheben, da sonst die <u>Datenintegrität gefährdet</u> ist.<br><br>
				<i>Weitere Infos finden Sie links im Menü unter "Weiteres > System".</i>
			</div>
		</div>
	</div>

	<div class="row">
		<div class="col-md-12 col-lg-6 offset-lg-3 ">
			<div class="alert alert-danger" role="alert"
				*ngIf="(userAgreement.initComplete$ | async) && (userAgreement.agreements$ | async).length === 0">
				<strong>Achtung:</strong><br>
				Sie haben noch keine Vereinbarungen angelegt. Um rechtssicher arbeiten zu können, sollten Sie mindestens eine
				Datenschutzerklärung bereitstellen.
			</div>
			<div class="card">
				<div class="card-header">
					Willkommen {{(user$ | async) ? (user$ | async).Username : ''}}!
				</div>
				<div class="card-body">
					Sie befinden sich im administrativen Bereich.<br>
					Bitte nutzen Sie die Navigation auf der linken Seite.
				</div>
			</div>
		</div>
	</div>
</div>