import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProfileService } from 'src/app/service/profile.service';
import { SystemService } from 'src/app/service/system.service';
import { UserAgreementService } from 'src/app/service/user-agreement.service';
import { IUser } from '../../models/User.model';
import { AuthenticationService } from '../../service/authentication.service';
import { ConfigService } from '../../service/config.service';

@Component({
	selector: 'app-page-welcome',
	templateUrl: './page-welcome.component.html',
	styleUrls: ['./page-welcome.component.scss']
})
export class PageWelcomeComponent implements OnInit {

	user$: Observable<IUser>;
	diskSpace$ = this.system.diskSpaceState$;

	apiConfig$ = this.config.apiConfig$;

	constructor(
		protected auth: AuthenticationService,
		protected profileService: ProfileService,
		protected config: ConfigService,
		public userAgreement: UserAgreementService,
		private system: SystemService,
	) {
		this.user$ = this.auth.user$;
	}

	ngOnInit() {
	}


	diskSpaceLow$() {
		return this.system.diskSpaceLow$();
	}

}
