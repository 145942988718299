import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-modal-alert',
	templateUrl: './modal-alert.component.html',
	styleUrls: ['./modal-alert.component.scss']
})
export class ModalAlertComponent implements OnInit {

	public buttonLabels = {
		okay: 'Okay'
	};

	public title: string;
	public description: string;

	constructor(
		protected activeModal: NgbActiveModal
	) { }

	ngOnInit() {
	}

	close() {
		this.activeModal.close(null);
	}

}
