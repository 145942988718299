import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { filter } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
	providedIn: 'root'
})
export class AppStateService {

	public refreshTrigger$: BehaviorSubject<boolean> = new BehaviorSubject(null);

	constructor(
		protected auth: AuthenticationService
	) {
		this.auth.socketAuthState$
			.pipe(
				filter((state: boolean) => {
					return state;
				})
			)
			.subscribe(() => {
				this.refreshState();
			});
	}

	public refreshState(): void {
		this.refreshTrigger$.next(true);
	}
}
