import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AvatarComponent } from './component/avatar/avatar.component';
import { ChatBubbleComponent } from './component/chat-bubble/chat-bubble.component';
import { NgxCroppieComponent } from './component/ngx-croppie/ngx-croppie.component';
import { AuthGuard } from './guards/auth.guard';
import { ModalAlertComponent } from './modals/modal-alert/modal-alert.component';
import { ModalConfirmationComponent } from './modals/modal-confirmation/modal-confirmation.component';
import { ModalPickerComponent } from './modals/modal-picker/modal-picker.component';
import { ModalPromptComponent } from './modals/modal-prompt/modal-prompt.component';
import { MainFrameComponent } from './pages/main-frame/main-frame.component';
import { PageBroadcastCreateComponent } from './pages/page-broadcast-create/page-broadcast-create.component';
import { PageBroadcastDetailComponent } from './pages/page-broadcast-detail/page-broadcast-detail.component';
import { PageBroadcastListComponent } from './pages/page-broadcast-list/page-broadcast-list.component';
import { PageChatDetailComponent } from './pages/page-chat-detail/page-chat-detail.component';
import { PageChatGroupCreateComponent } from './pages/page-chat-group-create/page-chat-group-create.component';
import { PageChatGroupListComponent } from './pages/page-chat-group-list/page-chat-group-list.component';
import { PageCreateAdminComponent } from './pages/page-create-admin/page-create-admin.component';
import { PageLoginComponent } from './pages/page-login/page-login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PageUserAgreementCreateComponent } from './pages/page-user-agreement-create/page-user-agreement-create.component';
import { PageUserAgreementDetailComponent } from './pages/page-user-agreement-detail/page-user-agreement-detail.component';
import { PageUserAgreementListComponent } from './pages/page-user-agreement-list/page-user-agreement-list.component';
import { PageUserCreateComponent } from './pages/page-user-create/page-user-create.component';
import { PageUserDetailComponent } from './pages/page-user-detail/page-user-detail.component';
import { PageUserListComponent } from './pages/page-user-list/page-user-list.component';
import { PageUserPrintComponent } from './pages/page-user-print/page-user-print.component';
import { PageWelcomeComponent } from './pages/page-welcome/page-welcome.component';
import { SideMenuComponent } from './pages/side-menu/side-menu.component';
import { LinkifyPipe } from './pipes/linkify.pipe';
import { PageSystemComponent } from './pages/page-system/page-system.component';

@NgModule({
	declarations: [
		AppComponent,
		PageLoginComponent,
		SideMenuComponent,
		PageNotFoundComponent,
		MainFrameComponent,
		PageWelcomeComponent,
		PageUserListComponent,
		PageUserCreateComponent,
		PageChatGroupCreateComponent,
		PageChatGroupListComponent,
		PageUserDetailComponent,
		ModalPickerComponent,
		PageChatDetailComponent,
		ChatBubbleComponent,
		LinkifyPipe,
		ModalAlertComponent,
		ModalPromptComponent,
		ModalConfirmationComponent,
		PageUserPrintComponent,
		NgxCroppieComponent,
		AvatarComponent,
		PageBroadcastListComponent,
		PageBroadcastCreateComponent,
		PageBroadcastDetailComponent,
		PageCreateAdminComponent,
		PageUserAgreementListComponent,
		PageUserAgreementDetailComponent,
		PageUserAgreementCreateComponent,
		PageSystemComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		FormsModule,
		FontAwesomeModule,
		AppRoutingModule,
		NgbModule,
		NgxWebstorageModule.forRoot(),
		NgxSpinnerModule,
		HttpClientModule,
		AngularEditorModule,
		HammerModule
	],
	entryComponents: [
		ModalPickerComponent,
		ModalAlertComponent,
		ModalPromptComponent,
		ModalConfirmationComponent
	],
	providers: [AuthGuard],
	bootstrap: [AppComponent]
})
export class AppModule { }
