import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { filter, map, tap } from 'rxjs/operators';
import { IErrorEvent } from '../models/ErrorEvent.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { IUser } from '../models/User.model';
import { AppStateService } from './app-state.service';
import { AuthenticationService } from './authentication.service';
import { SocketService } from './socket.service';
import { StoreService } from './store.service';


@Injectable({
	providedIn: 'root'
})
export class UserService {
	public users$: BehaviorSubject<IUser[]> = this.store.getSubject('userService:users', null);

	public recentlyCreatedUser = {
		name: '',
		firstname: '',
		lastname: '',
		username: '',
		password: '',
		email: ''
	};

	constructor(
		protected socket: SocketService,
		protected auth: AuthenticationService,
		protected store: StoreService,
		protected appStateService: AppStateService
	) {
		this.appStateService.refreshTrigger$
			.pipe(
				filter((state: boolean) => {
					return state;
				})
			)
			.subscribe(() => {
				this.refreshUsers();
			});
	}

	public getAll(): Observable<IUser[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:getAll',
				// sendPayload
				{},
				// successEventName
				'admin:user:getAll:success',
				// failEventName
				'admin:user:getAll:fail'
			)
			.pipe(
				map((event: ISocketEvent): IUser[] => {
					return event.payload;
				})
			);
	}

	/**
	 * Either emits the user or null if not found
	 *
	 * @param UserID
	 */
	public getUser(UserID: string): Observable<IUser> {
		return this.users$.pipe(
			filter((users: IUser[]): boolean => {
				return users !== null;
			}),
			map((users: IUser[]): IUser => {
				return users.find((user: IUser): boolean => {
					return user._id === UserID;
				});
			})
		);
	}

	public updatePassword(UserID: string, newPassword: string): Observable<boolean> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:updatePassword',
				// sendPayload
				{ UserID, Password: newPassword },
				// successEventName
				'admin:user:updatePassword:success',
				// failEventName
				'admin:user:updatePassword:fail'
			)
			.pipe(
				map((event: ISocketEvent): boolean => {
					return event.payload;
				})
			);
	}

	public create(
		Username: string,
		Password: string,
		Firstname: string,
		Lastname: string,
		Email: string,
		ChatGroupIDs: string[]
	): Observable<boolean> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:create',
				// sendPayload
				{
					user: {
						Username,
						Password,
						Firstname,
						Lastname,
						Email
					},

					chatGroupIDs: ChatGroupIDs
				},
				// successEventName
				'admin:user:create:success',
				// failEventName
				'admin:user:create:fail'
			)
			.pipe(
				map((event: ISocketEvent): boolean => {
					return event.payload;
				}),
				tap((): void => {
					// user data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public createInitAdmin(
		Username: string,
		Password: string,
		Firstname: string,
		Lastname: string,
		Email: string,
		licenceKey: string
	): Observable<boolean> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:init:createAdmin',
				// sendPayload
				{
					user: {
						Username,
						Password,
						Firstname,
						Lastname,
						Email
					},
					licenceKey
				},
				// successEventName
				'admin:init:createAdmin:success',
				// failEventName
				'admin:init:createAdmin:fail'
			)
			.pipe(
				map((event: ISocketEvent): boolean => {
					return event.payload;
				})
			);
	}

	public inactivate(UserID: string): Observable<IUser> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:inactivate',
				// sendPayload
				{
					UserID
				},
				// successEventName
				'admin:user:inactivate:success',
				// failEventName
				'admin:user:inactivate:fail'
			)
			.pipe(
				map((event: ISocketEvent): IUser => {
					return event.payload;
				}),
				tap((): void => {
					// user data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public activate(UserID: string): Observable<IUser> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:activate',
				// sendPayload
				{
					UserID
				},
				// successEventName
				'admin:user:activate:success',
				// failEventName
				'admin:user:activate:fail'
			)
			.pipe(
				map((event: ISocketEvent): IUser => {
					return event.payload;
				}),
				tap((): void => {
					// user data has changed, refetch it.
					this.appStateService.refreshState();
				})
			);
	}

	public kick(UserID: string): Observable<void> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:kick',
				// sendPayload
				{
					UserID
				},
				// successEventName
				'admin:user:kick:success',
				// failEventName
				'admin:user:kick:fail'
			)
			.pipe(
				map((event: ISocketEvent): void => {
					return;
				})
			);
	}

	public delete(UserID: string, KeepMessages: boolean = false, AlternateSenderName: string = null) {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:delete',
				// sendPayload
				{
					UserID,
					KeepMessages,
					AlternateSenderName
				},
				// successEventName
				'admin:user:delete:success',
				// failEventName
				'admin:user:delete:fail'
			)
			.pipe(
				map((event: ISocketEvent): void => {
					return;
				})
			);
	}

	public promote(UserID: string) {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:promote',
				// sendPayload
				{
					UserID
				},
				// successEventName
				'admin:user:promote:success',
				// failEventName
				'admin:user:promote:fail'
			)
			.pipe(
				map((event: ISocketEvent): void => {
					return;
				})
			);
	}

	public demote(UserID: string) {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:user:demote',
				// sendPayload
				{
					UserID
				},
				// successEventName
				'admin:user:demote:success',
				// failEventName
				'admin:user:demote:fail'
			)
			.pipe(
				map((event: ISocketEvent): void => {
					return;
				})
			);
	}

	public refreshUsers(): void {
		if (this.auth.socketAuthState$.getValue() === false) {
			return;
		}

		this.getAll().subscribe(
			(users: IUser[]) => {
				this.users$.next(this.sortUsers(users));
			},
			(err: IErrorEvent) => {
				alert(`Fehler: User konnten nicht geladen werden. Code:  ${err.status} | ${err.message} `);
			}
		);
	}

	protected sortUsers(users: IUser[]): IUser[] {
		return users.sort((a: IUser, b: IUser): number => {
			if (a.Username < b.Username) {
				return -1;
			}
			if (a.Username > b.Username) {
				return 1;
			}
			return 0;
		});
	}
}
