import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IChatBroadcastList } from '../models/ChatBroadcastList.model';
import { ISocketEvent } from '../models/SocketEvent.model';
import { SocketService } from './socket.service';
import { StoreService } from './store.service';

@Injectable({
	providedIn: 'root'
})
export class BroadcastListService {

	public lists$: BehaviorSubject<IChatBroadcastList[]> = this.store.getSubject('broadcastListService:lists', null);

	constructor(
		protected store: StoreService,
		protected socket: SocketService
	) {
		this.bindListeners();
	}

	create(Title: string, ReceiverIDs: string[]): Observable<IChatBroadcastList> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:broadcast:list:create',
				// sendPayload
				{ Title, ReceiverIDs },
				// successEventName
				'admin:chat:broadcast:list:create:success',
				// failEventName
				'admin:chat:broadcast:list:create:fail'
			).pipe(
				map((event: ISocketEvent): IChatBroadcastList => {
					return event.payload;
				}),
				tap(() => {
					this.refresh();
				})
			);
	}

	updateReceivers(ListID: string, ReceiverIDs: string[]): Observable<IChatBroadcastList> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:broadcast:list:receivers:update',
				// sendPayload
				{ ListID, ReceiverIDs },
				// successEventName
				'admin:chat:broadcast:list:receivers:update:success',
				// failEventName
				'admin:chat:broadcast:list:receivers:update:fail'
			).pipe(
				map((event: ISocketEvent): IChatBroadcastList => {
					return event.payload;
				}),
				tap(() => {
					this.refresh();
				})
			);
	}

	updateTitle(ListID: string, Title: string): Observable<IChatBroadcastList> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:broadcast:list:title:update',
				// sendPayload
				{ ListID, Title },
				// successEventName
				'admin:chat:broadcast:list:title:update:success',
				// failEventName
				'admin:chat:broadcast:list:title:update:fail'
			).pipe(
				map((event: ISocketEvent): IChatBroadcastList => {
					return event.payload;
				}),
				tap(() => {
					this.refresh();
				})
			);
	}

	remove(ListID: string): Observable<void> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:broadcast:list:remove',
				// sendPayload
				{ ListID },
				// successEventName
				'admin:chat:broadcast:list:remove:success',
				// failEventName
				'admin:chat:broadcast:list:remove:fail'
			).pipe(
				map((event: ISocketEvent): void => {
					return;
				}),
				tap(() => {
					this.refresh();
				})
			);
	}

	getAllBroadcastLists(): Observable<IChatBroadcastList[]> {
		return this.socket
			.pullRequest(
				// sendEventName
				'admin:chat:broadcast:list:getAll',
				// sendPayload
				{},
				// successEventName
				'admin:chat:broadcast:list:getAll:success',
				// failEventName
				'admin:chat:broadcast:list:getAll:fail'
			).pipe(
				map((event: ISocketEvent): IChatBroadcastList[] => {
					return event.payload;
				})
			);
	}

	observeByID(ListID: string): null | Observable<IChatBroadcastList> {
		if (!this.doesIDExist(ListID)) {
			return null;
		}

		return this.lists$.pipe(
			map((lists: IChatBroadcastList[]) => {
				return lists.find((list) => {
					return list._id === ListID;
				});
			})
		);
	}

	refresh() {
		this.getAllBroadcastLists().subscribe((bcLists) => {
			this.lists$.next(bcLists);
		});
	}

	protected doesIDExist(ListID: string): boolean {
		const lists = this.lists$.getValue();

		const index = lists.findIndex((list) => {
			return list._id === ListID;
		});

		return index !== -1;
	}


	protected bindListeners(): void {
		// Whenever the profile is emitted, put it into the store
		this.socket
			.observe('chat:broadcast:lists')
			.subscribe((event: ISocketEvent) => {
				const bcLists: IChatBroadcastList[] = event.payload;

				this.setLists(bcLists);
			});
	}

	protected setLists(bcLists: IChatBroadcastList[]) {
		this.lists$.next(this.sortLists(bcLists));
	}

	protected sortLists(lists: IChatBroadcastList[]): IChatBroadcastList[] {
		return lists.sort((a: IChatBroadcastList, b: IChatBroadcastList): number => {
			if (a.Title < b.Title) { return -1; }
			if (a.Title > b.Title) { return 1; }
			return 0;
		});
	}
}
