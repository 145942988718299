import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

export function convertObservableToBehaviorSubject<T>(observable: Observable<T>): BehaviorSubject<T> {
	// Create the subject
	const subject = new BehaviorSubject<T>(null);

	// Subscribe to the observable and map events to the subject
	observable
		.subscribe((value: T): void => {
			subject.next(value);
		}, (err: any): void => {
			subject.error(err);
		}, (): void => {
			subject.complete();
		});

	// Eventually return the subject
	return subject;
}
