import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons';
import { NgxSpinnerService } from 'ngx-spinner';
import { IPickerOption } from 'src/app/modals/modal-picker/picker-option.interface';
import { IErrorEvent } from 'src/app/models/ErrorEvent.model';
import { IUser } from 'src/app/models/User.model';
import { BroadcastListService } from 'src/app/service/broadcast-list.service';
import { ModalService } from 'src/app/service/modal.service';
import { UserService } from 'src/app/service/user.service';

@Component({
	selector: 'app-page-broadcast-create',
	templateUrl: './page-broadcast-create.component.html',
	styleUrls: ['./page-broadcast-create.component.scss']
})
export class PageBroadcastCreateComponent implements OnInit {

	// VIEW CHILDREN //
	@ViewChild('createForm')
	form: NgForm;

	// MODELS //
	title = '';
	users: IPickerOption[] = [];

	// UI //
	icons = {
		bcList: faUsersClass,
		refresh: faSync,
		search: faSearch,
		plus: faPlusCircle
	};

	constructor(
		protected modalService: ModalService,
		protected userService: UserService,
		protected spinner: NgxSpinnerService,
		protected bcListService: BroadcastListService
	) {
		this.setupUsers();
	}

	ngOnInit() {
	}

	submit(): void {
		this.spinner.show();
		this.bcListService.create(
			this.title,
			this.getSelectedUserIDs()
		).subscribe(() => {
			this.spinner.hide();
			alert(`Erfolg! Die Verteilerliste "${this.title}" wurde erstellt.`);
			this.reset();
		}, (err: IErrorEvent) => {
			this.spinner.hide();
			alert(`Fehler: Die Verteilerliste konnte nicht erstellt werden. Code: ${err.status} - ${err.message}`);
		});
	}

	reset() {
		this.title = '';
		this.setupUsers();

		this.form.reset();
	}

	manageReceivers(): void {
		const title = `Empfänger auswählen`;
		const description = `Markieren Sie die Benutzer, die als Empfänger in die Verteilerliste aufgenommen werden sollen.`;

		this.modalService
			.picker(this.users, title, description)
			.subscribe((result: IPickerOption[]) => {
				this.users = result;
			}, (err: any) => {
				console.log('closed with error', err);
			});
	}

	getSelectedUserIDs(): string[] {
		const userIDs: string[] = this.getEnabledOptions()
			.map((option: IPickerOption) => {
				return option.value;
			});

		return userIDs;
	}


	getEnabledOptions(): IPickerOption[] {
		return this.users.filter((option: IPickerOption) => {
			return option.enabled;
		});
	}


	setupUsers(): void {
		const allUsers: IUser[] = this.userService.users$.getValue();

		// Generate options for the picker
		this.users = allUsers.map((user: IUser): IPickerOption => {
			return {
				label: user.Username,
				enabled: false,
				value: user._id
			};
		});
	}

}
