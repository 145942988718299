import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faSearch, faSync } from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass } from '@fortawesome/pro-solid-svg-icons';
import * as moment from 'moment';
import { IChatBroadcastList } from 'src/app/models/ChatBroadcastList.model';
import { BroadcastListService } from 'src/app/service/broadcast-list.service';
import { ChatService } from 'src/app/service/chat.service';
import { ProfileService } from 'src/app/service/profile.service';
import { UserService } from 'src/app/service/user.service';

@Component({
	selector: 'app-page-broadcast-list',
	templateUrl: './page-broadcast-list.component.html',
	styleUrls: ['./page-broadcast-list.component.scss']
})
export class PageBroadcastListComponent implements OnInit {

	bcLists$ = this.broadcastListService.lists$;
	searchTerm: string;

	icons = {
		bcList: faUsersClass,
		refresh: faSync,
		search: faSearch,
	};

	constructor(
		protected broadcastListService: BroadcastListService,
		protected router: Router,
		protected userService: UserService,
		protected profileService: ProfileService,
		protected chatService: ChatService
	) { }

	ngOnInit() { }


	navigateToBroadcastDetail(bcList: IChatBroadcastList) {
		if (!bcList) {
			return;
		}
		this.navigateTo('chat-broadcast-detail/' + bcList._id);
	}

	navigateTo(target: string) {
		this.router.navigate(['main/' + target]);
	}

	filter(searchTerm: string, bcLists: IChatBroadcastList[]): IChatBroadcastList[] {
		if (!searchTerm || searchTerm.length === 0) {
			return bcLists;
		}

		return bcLists.filter((bcList: IChatBroadcastList): boolean => {
			const terms: string[] = searchTerm.toLowerCase().split(/[ ,]+/);
			const val: string = bcList.Title.toLocaleLowerCase();

			let result: boolean = false;

			terms.forEach((term: string) => {
				if (val.indexOf(term) !== -1) {
					result = true;
				}
			});

			return result;
		});
	}

	formatDate(dateString: string | Date) {
		const date = moment(dateString);

		return `${date.format('DD.MM.YYYY')}, ${date.format('HH:mm')} Uhr`;
	}

	refresh() {
		this.userService.refreshUsers();
		this.profileService.refreshProfiles();
		this.chatService.refreshConversations();
		this.broadcastListService.refresh();
	}

}
