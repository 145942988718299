<div class="modal-header">
	<h5 class="modal-title">{{title}}</h5>
	<button type="button" class="close" aria-label="Close" (tap)="abort();">
	<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body">
	<p>{{description}}</p>
	<form class="form-inline">
		<label class="sr-only" for="inlineFormInputGroupUsername2">Suche</label>
		<div class="input-group mb-2 mr-sm-2">
			<div class="input-group-prepend">
				<div class="input-group-text"><fa-icon [icon]="icons.search"></fa-icon></div>
			</div>
			<input type="text" class="form-control" name="searchTerm" placeholder="Suche" [(ngModel)]="searchTerm" [ngModelOptions]="{standalone: true}">
			<span class="btn btn-light" (tap)="searchTerm = ''"><fa-icon [icon]="icons.clear"></fa-icon></span>
		</div>
	</form>
	<div class="scroll-area">
		<ul class="list-group">
			<li *ngFor="let option of filter(searchTerm, options)" (tap)="toggle(option);" class="list-group-item">
				<fa-icon [icon]="option.enabled ? icons.checkSquare : icons.square"></fa-icon> {{option.label}}
			</li>
		</ul>
	</div>
</div>
<div class="modal-footer">
	<button type="button" class="btn btn-secondary" (tap)="abort();">Abbrechen</button>
	<button type="button" class="btn btn-primary" (tap)="close(options);">Übernehmen</button>
</div>
